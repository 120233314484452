import React from "react";





class Saint extends React.Component {
  componentDidMount(){
    

     // set Interval
     this.interval = setInterval(this.forceUpdate.bind(this), 360*60*1000);
}
componentWillUnmount() {
  // Clear the interval right before component unmount
  clearInterval(this.interval);
}

  render() {
    const divStyle = {
      overflow: "hidden",
      display: "flex",
      textAlign: "center",
      backgroundColor: this.props.item.background,
      width: "100%",
      height: "100%",
      fontFamily: this.props.item.textFont,
      color: this.props.item.color,
    };

    const textSaint = {
      fontFamily: this.props.item.textFont,
      color: this.props.item.color,
      fontSize: this.props.item.textSize + "px",
      alignSelf: "center",
      textAlign: "center",
      width: "100%",
      margin: "0",
    };

    return (
      <>
        <div style={divStyle}>
          <h1 style={textSaint}>{Ephemeride.getTodayEphemeris()}</h1>
        </div>
      </>
    );
  }
}

//***TEMP SAINT CODE***

const Ephemeride = {
  source: {
    january: [
      ["Jour de l'An", ""],
      ["Basile", "Saint"],
      ["Geneviève", "Sainte"],
      ["Odilon", "Saint"],
      ["Edouard", "Saint"],
      ["Mélaine", "Saint"],
      ["Raymond", "Saint"],
      ["Lucien", "Saint"],
      ["Alix", "Sainte"],
      ["Guillaume", "Saint"],
      ["Pauline", "Saint"],
      ["Tatiana", "Sainte"],
      ["Yvette", "Sainte"],
      ["Nina", "Sainte"],
      ["Rémi", "Saint"],
      ["Marcel", "Saint"],
      ["Roseline", "Sainte"],
      ["Prisca", "Sainte"],
      ["Marius", "Saint"],
      ["Sébastien", "Saint"],
      ["Agnès", "Sainte"],
      ["Vincent", "Saint"],
      ["Barnard", "Saint"],
      ["François de Sales", "Saint"],
      ["Conversion de Paul", ""],
      ["Paule", "Sainte"],
      ["Angèle", "Sainte"],
      ["Thomas d'Aquin", "Saint"],
      ["Gildas", "Saint"],
      ["Martine", "Sainte"],
      ["Marcelle", "Sainte"],
    ],
    february: [
      ["Ella", "Sainte"],
      ["Fête de la Présention", ""],
      ["Blaise", "Saint"],
      ["Véronique", "Sainte"],
      ["Agathe", "Sainte"],
      ["Gaston", "Saint"],
      ["Eugènie", "Sainte"],
      ["Jacqueline", "Sainte"],
      ["Apolline", "Sainte"],
      ["Arnaud", "Saint"],
      ["Notre Dame de Lourdes", "Sainte"],
      ["Félix", "Saint"],
      ["Béatrice", "Sainte"],
      ["Valentin", "Saint"],
      ["Claude", "Saint"],
      ["Julienne", "Sainte"],
      ["Alexis", "Saint"],
      ["Bernadette", "Sainte"],
      ["Gabin", "Saint"],
      ["Aimée", "Sainte"],
      ["Mercredi des Cendres", ""],
      ["Isabelle", "Sainte"],
      ["Lazare", "Saint"],
      ["Modeste", "Sainte"],
      ["Roméo", "Saint"],
      ["Nestor", "Saint"],
      ["Honorine", "Sainte"],
      ["Romain", "Saint"],
      ["Auguste", "Saint"],
    ],
    march: [
      ["Aubin", "Saint"],
      ["Charles le Bon", "Saint"],
      ["Guénolé", "Saint"],
      ["Casimir", "Saint"],
      ["Olive", "Saint"],
      ["Colette", "Sainte"],
      ["Félicité", "Sainte"],
      ["Jean de Dieu", "Saint"],
      ["Françoise", "Sainte"],
      ["Vivien", "Saint"],
      ["Rosine", "Sainte"],
      ["Justine", "Sainte"],
      ["Rodrigue", "Saint"],
      ["Mathilde", "Sainte"],
      ["Louise", "Sainte"],
      ["Bénédicte", "Sainte"],
      ["Patrice", "Saint"],
      ["Cyrille", "Saint"],
      ["Joseph", "Saint"],
      ["Herbert", "Saint"],
      ["Clémence", "Sainte"],
      ["Léa", "Sainte"],
      ["Victorien", "Saint"],
      ["Karine", "Sainte"],
      ["Anne", "Sainte"],
      ["Larissa", "Sainte"],
      ["Habib", "Saint"],
      ["Gontran", "Saint"],
      ["Gwladys", "Sainte"],
      ["Amédée", "Saint"],
      ["Benjamin", "Saint"],
    ],
    april: [
      ["Hugues", "Saint"],
      ["Sandrine", "Sainte"],
      ["Richard", "Saint"],
      ["Isidore", "Saint"],
      ["Irène", "Sainte"],
      ["Marcellin", "Saint"],
      ["Jean-Baptiste de la Salle", "Saint"],
      ["Julie", "Sainte"],
      ["Gautier", "Saint"],
      ["Fulbert", "Saint"],
      ["Stanislas", "Saint"],
      ["Jules", "Saint"],
      ["Ida", "Sainte"],
      ["Maxime", "Saint"],
      ["Paterne", "Saint"],
      ["Benoît-Joseph", "Saint"],
      ["Anicet", "Saint"],
      ["Parfait", "Saint"],
      ["Emma", "Sainte"],
      ["Odette", "Sainte"],
      ["Anselme", "Saint"],
      ["Alexandre", "Saint"],
      ["Georges", "Saint"],
      ["Fidèle", "Saint"],
      ["Marc", "Saint"],
      ["Alida", "Sainte"],
      ["Zita", "Sainte"],
      ["Valérie", "Sainte"],
      ["Catherine de Sienne", "Sainte"],
      ["Robert", "Saint"],
    ],
    may: [
      ["Fête du travail", ""],
      ["Boris", "Saint"],
      ["Philippe", "Saint"],
      ["Sylvain", "Saint"],
      ["Judith", "Saint"],
      ["Prudence", "Saint"],
      ["Gisèle", "Sainte"],
      ["Armistice de 1945", ""],
      ["Pacôme", "Saint"],
      ["Solange", "Sainte"],
      ["Estelle", "Sainte"],
      ["Achille", "Saint"],
      ["Rolande", "Sainte"],
      ["Matthias", "Saint"],
      ["Denise", "Sainte"],
      ["Honoré", "Saint"],
      ["Pascal", "Saint"],
      ["Eric", "Saint"],
      ["Yves", "Saint"],
      ["Bernardin", "Saint"],
      ["Constantin", "Saint"],
      ["Emile", "Saint"],
      ["Didier", "Saint"],
      ["Donatien", "Saint"],
      ["Sophie", "Sainte"],
      ["Bérenger", "Saint"],
      ["Augustin", "Saint"],
      ["Germain", "Saint"],
      ["Aymar", "Saint"],
      ["Ferdinand", "Saint"],
      ["Ferdinand", "Saint"],
    ],
    june: [
      ["Justin", "Saint"],
      ["Blandine", "Sainte"],
      ["Kévin", "Saint"],
      ["Clotilde", "Sainte"],
      ["Igor", "Saint"],
      ["Norbert", "Saint"],
      ["Gilbert", "Saint"],
      ["Médard", "Saint"],
      ["Diane", "Sainte"],
      ["Landry", "Saint"],
      ["Barnabé", "Saint"],
      ["Guy", "Saint"],
      ["Antoine de Padoue", "Saint"],
      ["Elisée", "Sainte"],
      ["Germaine", "Sainte"],
      ["Jean-François Régis", "Saint"],
      ["Hervé", "Saint"],
      ["Léonce", "Saint"],
      ["Romuald", "Saint"],
      ["Silvère", "Saint"],
      ["Solstice d'été", ""],
      ["Alban", "Saint"],
      ["Audrey", "Sainte"],
      ["Jean-Baptiste", "Saint"],
      ["Prosper", "Saint"],
      ["Anthelme", "Saint"],
      ["Fernand", "Saint"],
      ["Irénée", "Saint"],
      ["Pierre", "Saint"],
      ["Martial", "Saint"],
    ],
    july: [
      ["Thierry", "Saint"],
      ["Martinien", "Saint"],
      ["Thomas", "Saint"],
      ["Florent", "Saint"],
      ["Antoine", "Saint"],
      ["Mariette", "Sainte"],
      ["Raoul", "Saint"],
      ["Thibault", "Saint"],
      ["Amandine", "Sainte"],
      ["Ulrich", "Saint"],
      ["Benoît", "Saint"],
      ["Olivier", "Saint"],
      ["Henri", "Saint"],
      ["Fête Nationale", ""],
      ["Donald", ""],
      ["Fête de Notre Dame du Mont Carmel", ""],
      ["Charlotte", "Sainte"],
      ["Frédéric", "Saint"],
      ["Arsène", "Saint"],
      ["Marina", "Sainte"],
      ["Victor", "Saint"],
      ["Marie-Madeleine", "Sainte"],
      ["Brigitte", "Sainte"],
      ["Christine", "Sainte"],
      ["Jacques", "Saint"],
      ["Anne", "Sainte"],
      ["Nathalie", "Sainte"],
      ["Samson", "Saint"],
      ["Marthe", "Sainte"],
      ["Juliette", "Sainte"],
      ["Ignace de Loyola", "Saint"],
    ],
    august: [
      ["Alphonse", "Saint"],
      ["Julien Eymard", "Saint"],
      ["Lydie", "Sainte"],
      ["Jean-Marie Vianney", "Saint"],
      ["Abel", "Saint"],
      ["Fête de la Transfiguration", ""],
      ["Gaétan", "Saint"],
      ["Dominique", "Saint"],
      ["Amour", "Saint"],
      ["Laurent", "Saint"],
      ["Claire", "Sainte"],
      ["Clarisse", "Sainte"],
      ["Hippolyte", "Saint"],
      ["Evrard", "Saint"],
      ["Fête de l'Assomption", ""],
      ["Armel", "Sainte"],
      ["Hyacinthe", "Saint"],
      ["Hélène", "Sainte"],
      ["Jean-Eudes", "Saint"],
      ["Bernard", "Saint"],
      ["Christophe", "Saint"],
      ["Fabrice", "Saint"],
      ["Rose de Lima", "Sainte"],
      ["Barthélémy", "Saint"],
      ["Louis", "Saint"],
      ["Natacha", "Sainte"],
      ["Monique", "Sainte"],
      ["Augustin", "Saint"],
      ["Sabine", "Sainte"],
      ["Fiacre", "Saint"],
      ["Aristide", "Saint"],
    ],
    september: [
      ["Gilles", "Saint"],
      ["Ingrid", "Sainte"],
      ["Grégoire", "Saint"],
      ["Rosalie", "Sainte"],
      ["Raïssa", "Sainte"],
      ["Bertrand", "Saint"],
      ["Reine", "Sao,te"],
      ["Fête de la Nativité", ""],
      ["Alain", "Saint"],
      ["Inès", "Sainte"],
      ["Adelphe", "Saint"],
      ["Apollinaire", "Saint"],
      ["Aimé", "Saint"],
      ["Fête de la Croix Glorieuse", ""],
      ["Roland", "Saint"],
      ["Edith", "Sainte"],
      ["Renaud", "Saint"],
      ["Nadège", "Sainte"],
      ["Emilie", "Sainte"],
      ["Davy", "Saint"],
      ["Matthieu", "Saint"],
      ["Maurice", "Saint"],
      ["Equinoxe d'Automne", ""],
      ["Thècle", "Sainte"],
      ["Hermann", "Saint"],
      ["Côme", "Saint"],
      ["Vincent de Paul", "Saint"],
      ["Venceslas", "Saint"],
      ["Michel", "Saint"],
      ["Jérôme", "Saint"],
    ],
    october: [
      ["Thérèse de l'Enfant Jésus", "Sainte"],
      ["Léger", "Saint"],
      ["Gérard", "Saint"],
      ["François d'Assise", "Saint"],
      ["Fleur", "Sainte"],
      ["Bruno", "Saint"],
      ["Serge", "Saint"],
      ["Pélagie", "Sainte"],
      ["Denis", "Saint"],
      ["Ghislain", "Saint"],
      ["Firmin", "Saint"],
      ["Wilfried", "Saint"],
      ["Géraud", "Saint"],
      ["Juste", "Saint"],
      ["Thérèse d'Avila", "Saint"],
      ["Edwige", "Sainte"],
      ["Baudoin", "Saint"],
      ["Luc", "Saint"],
      ["René", "Saint"],
      ["Adeline", "Sainte"],
      ["Céline", "Sainte"],
      ["Elodie", "Sainte"],
      ["Jean de Capistran", "Saint"],
      ["Florentin", "Saint"],
      ["Crépin", "Saint"],
      ["Dimitri", "Saint"],
      ["Emeline", "Sainte"],
      ["Jude", "Saint"],
      ["Narcisse", "Saint"],
      ["Bienvenue", "Sainte"],
      ["Quentin", "Saint"],
    ],
    november: [
      ["Toussaint", ""],
      ["Fête des défunts", ""],
      ["Hubert", "Saint"],
      ["Charles", "Saint"],
      ["Sylvie", "Sainte"],
      ["Bertille", "Sainte"],
      ["Carine", "Sainte"],
      ["Geoffroy", "Saint"],
      ["Théodore", "Saint"],
      ["Léon", "Saint"],
      ["Armistice de 1918", ""],
      ["Christian", "Saint"],
      ["Brice", "Saint"],
      ["Sidoine", "Saint"],
      ["Albert", "Saint"],
      ["Marguerite", "Sainte"],
      ["Elisabeth", "Sainte"],
      ["Aude", "Sainte"],
      ["Tanguy", "Saint"],
      ["Edmond", "Saint"],
      ["Présence de Marie", ""],
      ["Cécile", "Sainte"],
      ["Clément", "Saint"],
      ["Flora", "Sainte"],
      ["Catherine", "Sainte"],
      ["Delphine", "Sainte"],
      ["Sévrin", "Saint"],
      ["Jacques de la Marche", "Saint"],
      ["Saturnin", "Saint"],
      ["André", "Saint"],
    ],
    december: [
      ["Florence", "Sainte"],
      ["Viviane", "Sainte"],
      ["François-Xavier", "Saint"],
      ["Barbara", "Sainte"],
      ["Gérald", "Saint"],
      ["Nicolas", "Saint"],
      ["Ambroise", "Sainte"],
      ["Fête de l'Immaculée Conception", ""],
      ["Pierre Fourier", "Saint"],
      ["Romaric", "Saint"],
      ["Daniel", "Saint"],
      ["Jeanne-Françoise de Chantal", "Sainte"],
      ["Lucie", "Sainte"],
      ["Odile", "Sainte"],
      ["Ninon", "Sainte"],
      ["Alice", "Sainte"],
      ["Gaël", "Saint"],
      ["Gatien", "Saint"],
      ["Urbain", "Saint"],
      ["Théophile", "Saint"],
      ["Solstice d'Hiver", ""],
      ["Françoise-Xavière", "Sainte"],
      ["Armand", "Saint"],
      ["Adèle", "Sainte"],
      ["Noël", ""],
      ["Etienne", "Saint"],
      ["Jean", "Saint"],
      ["Innocents", "Saints"],
      ["David", "Saint"],
      ["Roger", "Saint"],
      ["Sylvestre", "Saint"],
    ],
  },

  /**
   * Get the month raw output.
   *
   * If invalid or incorrect parameter, undefined is returned.
   *
   * @param {number} month
   *  integer from 1 to 12
   */
  getMonth: function (month) {
    if (month === 1) return this.source.january;
    if (month === 2) return this.source.february;
    if (month === 3) return this.source.march;
    if (month === 4) return this.source.april;
    if (month === 5) return this.source.may;
    if (month === 6) return this.source.june;
    if (month === 7) return this.source.july;
    if (month === 8) return this.source.august;
    if (month === 9) return this.source.september;
    if (month === 10) return this.source.october;
    if (month === 11) return this.source.november;
    if (month === 12) return this.source.december;
  },

  /**
   * Get the full ephemeris.
   *
   * If invalid or incorrect parameter, undefined is returned.
   *
   * @param {number} day
   *   integer from 1 to 31
   * @param {number} month
   *   integer from 1 to 12
   * @returns {string|undefined}
   */
  getEphemeris: function (day, month) {
    try {
      var prefix = this.getMonth(month)[day - 1][1];

      return prefix === ""
        ? this.getMonth(month)[day - 1][0]
        : prefix + " " + this.getMonth(month)[day - 1][0];
    } catch (err) {}
  },

  /**
   * Get the ephemeris name (without the prefix).
   * E.g. if the ephemeris is 'Saint Ella', the string 'Ella' will be returned.<br />
   * <br />
   * If invalid or incorrect parameter, undefined is returned.
   *
   * @param {number} day
   *   integer from 1 to 31
   * @param {number} month
   *   integer from 1 to 12
   * @returns {string}
   */
  getEphemerisName: function (day, month) {
    try {
      return this.getMonth(month)[day - 1][0];
    } catch (err) {}
  },

  /**
   * Get today ephemeris.
   *
   * @returns {string}
   */
  getTodayEphemeris: function () {
    var today = new Date();

    return this.getEphemeris(today.getDate(), today.getMonth() + 1);
  },

  /**
   * Get today ephemeris name (without the prefix).
   * E.g. if the ephemeris is 'Saint Ella', the string 'Ella' will be returned.<br />
   *
   * @returns {string}
   */
  getTodayEphemerisName: function () {
    var today = new Date();

    return this.getEphemerisName(today.getDate(), today.getMonth() + 1);
  },
};

export default Saint;
