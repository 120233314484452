import React from "react";
import GridLayout from "react-grid-layout";
import { getFromLS } from "../utils/Utils";
import { saveToLS } from "../utils/Utils";

import {
  Background,
  Bar,
  DateAndTime,
  Dispatch,
  FluidityLine,
  FluidityRectangle_Port,
  FoodGroup_Port,
  HorizontalLine,
  ImageComponent,
  InfoFluidity,
  Logo,
  MultiFoodGroup_Port,
  Promotion,
  PromoVertical,
  Saint,
  TitleBar,
  TitleText,
  VerticalLine,
  Weather,
} from "../components/displayComponents/index";
import { getDeviceOfDisplay, loadDisplay } from "../api/Api";

const originalLayout = getFromLS("layout") || [];
const originalItems = getFromLS("items") || [];

class PreviewPortrait extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: JSON.parse(JSON.stringify(originalLayout)),
      items: JSON.parse(JSON.stringify(originalItems)),
      loadingDisplay: false,
    };
  }

  componentDidMount() {}

  createElement(el) {
    switch (el.i.substring(0, 3)) {
      case "BAC":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <Background
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );

      case "BAR":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <Bar
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );

      case "PRO":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <Promotion
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              currentDisplay={46}
              layout={this.state.layout}
            />
          </div>
        );
      case "PRV":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <PromoVertical
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              currentDisplay={46}
              layout={this.state.layout}
            />
          </div>
        );
      case "LOG":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <Logo
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "IMG":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <ImageComponent
              onRemoveItem={this.onRemoveItem}
              item={el}
              onStyleChange={this.onStyleChange}
              onLayerChange={this.onLayerChange}
            />
          </div>
        );
      case "TIT":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <TitleBar
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
              layout={this.state.layout}
            />
          </div>
        );
      case "MLH":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <HorizontalLine
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "MLV":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <VerticalLine
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "DAT":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <DateAndTime
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "SAN":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <Saint
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "WEA":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <Weather
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
              // currentDisplay={this.props.location.state.displayID}
            />
          </div>
        );
      case "FLL":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <FluidityLine
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "FLR":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            className="noresize"
          >
            <FluidityRectangle_Port
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
              onFluidityRectangleSizeChange={this.onFluidityRectangleSizeChange}
            />
          </div>
        );
      case "FIM":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
          >
            <InfoFluidity
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
      case "DSM":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            className="noresize"
          >
            <Dispatch
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
              onDispatchSizeChange={this.onDispatchSizeChange}
            />
          </div>
        );
      case "FGP":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
            className="noresize"
          >
            <FoodGroup_Port
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
              onFoodGroupLandSizeChange={this.onFoodGroupLandSizeChange}
            />
          </div>
        );
      case "TTX":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <TitleText
              onRemoveItem={this.onRemoveItem}
              item={el}
              onStyleChange={this.onStyleChange}
              onLayerChange={this.onLayerChange}
            />
          </div>
        );
      case "MFG":
        return (
          <div
            key={el.i}
            style={{ zIndex: el.zIndex }}
            {...this.props}
            className={this.props.className}
            isDraggable={false}
            isBounded={false}
          >
            <MultiFoodGroup_Port
              onRemoveItem={this.onRemoveItem}
              item={el}
              onLayerChange={this.onLayerChange}
              onStyleChange={this.onStyleChange}
            />
          </div>
        );
    }
  }

  render() {
    if (this.state.loadingDisplay === true) {
      return <div></div>;
    } else {
      return (
        <div>
          <GridLayout
            {...this.props}
            className="layout portrait"
            layout={this.state.layout}
            width={1080}
            rowHeight={10}
            maxRows={192}
            cols={108}
            verticalCompact={false}
            preventCollision={true}
            margin={[0, 0]}
            containerPadding={[0, 0]}
            autoSize
            isDraggable={false}
            isResizable={false}
          >
            {this.state.items.map((el) => this.createElement(el))}
          </GridLayout>
        </div>
      );
    }
  }
}

export default PreviewPortrait;
