
class Item {

    constructor(i, x, maxH, maxW, minH, minW, nameComp) {
        this.i = i;
        this.x = x;
        this.nameComp=nameComp;
        this.y = 1;
        this.h = minH;
        this.w = minW;
        this.maxH = maxH;
        this.maxW = maxW;
        this.minH = minH;
        this.minW = minW;
        this.text = this.i;
        this.zIndex = "1"
    }
}

export default Item;