import Item from './Item';

class TitleTextModel extends Item {

    constructor(i, x, maxH, maxW, minH, minW) {

        super(i, x, maxH, maxW, minH, minW);
        this.backgroundColor = "#D6D6D6";
        this.textColor = "black";
        this.text = "Hello";
        this.fontFamily = "Montserrant"
        this.fontSize = "20"
    }

}

export default TitleTextModel;