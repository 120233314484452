import Item from './Item';

class FluidityRectangleModel extends Item {

    constructor(i, x, maxH, maxW, minH, minW, widhtSize, heightSize, background, zona, textColor, textFont, textSize, image) {

        super(i, x, maxH, maxW, minH, minW);
        this.background = background;
        this.zona = zona;
        this.zoneName = "ZONE NAME";
        this.textColor = textColor;
        this.textFont = textFont;
        this.textSize = textSize;
        this.image = image;
        this.widhtSize = widhtSize;
        this.heightSize = heightSize;
        this.w = this.widhtSize;
        this.h = this.heightSize;
    }

}

export default FluidityRectangleModel;