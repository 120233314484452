import React from "react";
import {
  getZonesOfDisplayByRestaurant,
  getValueLive,
} from "../../api/Api";

class Disapatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_restaurant: this.props.item.restaurantID,
      dispatch: this.props.item.textDispatch,
      zone_valuelive: [],
      fillandzone: [],
      mapfluidity: "",
      fill: "",
    };
  }

  componentDidMount() {
    this.getZone()
    this.interval = setInterval(this.getZone.bind(this), 60000)
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }



  //get ZONE FROM DISPLAY ID
  getZone = () => {
    if (this.state.dispatch) {
      console.log(this.state.id_restaurant)
      getZonesOfDisplayByRestaurant(this.state.id_restaurant).then((res) => {
        console.log(res)
        this.updateColorMap(res);
      });
    }
  };

  getFluidityLive = (id_zone) => {
    getValueLive(id_zone).then((res) => {
      //null case
      if (res == null) {
        this.setState({ zone_valuelive: 0 });
      } else {
        this.setState({ zone_valuelive: res[0].value_live });
      }
    });
  };

  //Select the color of map zones in an array [fillandzone=(id and color)]
  updateColorMap(zonelist) {



    if (zonelist && zonelist[0]) {
      let fillandzone = [];

      zonelist.map((zona) => {
        let name = zona.region_id;

        if (zona.capacity === 0) {
              //Grey (the max capacity is Zero)
          this.setState({ fill: "#c6c6c6"})
}
        else if ((zona.level * 100) < zona.fluidity_conf.fluidity_triggers[1].level) {
          //Green
          this.setState({ fill: zona.fluidity_conf.fluidity_triggers[0].color })
        } else if ((zona.level * 100) < zona.fluidity_conf.fluidity_triggers[2].level) {
          //Orange
          this.setState({ fill: zona.fluidity_conf.fluidity_triggers[1].color })
        } else {
          //Red
          this.setState({ fill: zona.fluidity_conf.fluidity_triggers[2].color })
        }

        fillandzone.push({
          fill: this.state.fill,
          name: name,
        });
      })

      this.setState({ fillandzone: fillandzone });
      this.updateStyleMap();
    }
  }

  updateStyleMap() {
    let orderlist = [];
    let mapstyle;

    this.state.fillandzone.map((zonaColor) => {
      let fill = zonaColor.fill;
      let zoneid = zonaColor.name;

      if (document.getElementById(`zone_id${zoneid}`)) {
        let zone_idname = document.getElementById(`zone_id${zoneid}`);
        let numberorder = zone_idname.getElementsByTagName("*")[0].id;

        orderlist.push({ numberorder: numberorder, zoneid: zoneid, fill: fill });
      }
    })


    mapstyle = orderlist.map(
      (faz) => `#zone_id${faz.zoneid} *:first-child{fill:${faz.fill};}`
    );

    this.setState({ mapfluidity: mapstyle });
  }

  render() {
    const divStyle = {
      padding: "0",
      backgroundColor: this.props.item.background,
      width: this.props.item.widhtSize * 10 + "px",
      height: this.props.item.heightSize * 10 + "px",
      boxShadow: " 5px 10px 30px rgba(0,0,0,.2)",
      display: "flex",
      flexDirection: "column",
    };

    const mapStyle = {
      padding: "15px 0",
      width: "100%",
      height: "100%",
    };

    return (
      <>
        <div style={divStyle}>
          <div>
            <style>{this.state.mapfluidity}</style>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.dispatch }}
              style={mapStyle}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Disapatch;
