import React from "react";
import { getFoodGroupByID } from "../../api/Api";

class TitleBar extends React.Component {
  constructor(props) {
    super(props);
    this.titleBar = React.createRef();
    this.state = {
      text: "",
      image: "",
      actualHeight: 0,
    };
  }
  componentDidMount() {

    this.setWidth();
    this.setTitle();
    // set Interval
    this.interval = setInterval(this.getFoodItems.bind(this), 1800 * 1000);
  }
  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  setTitle() {
    if (this.props.item.id == "" || typeof this.props.item.id === "undefined") {
      this.setState({ text: this.props.item.text });
      this.setState({ image: this.props.item.icon });
    } else {
     this.getFoodItems();
    }
  }

  setWidth() {
    this.props.layout.forEach((element) => {
      if (element.i === this.props.item.i) {
        this.setState({ actualHeight: element.h });
      }
    });
  }


  //get FOOD Item FROM FOOD GROUPID
  getFoodItems() {
    getFoodGroupByID(this.props.item.id).then((res) => {
      if (res) {
        this.setState({ text: res.name });
        this.svgRender(res.picto);
      }
    });
  }

  svgRender(svgPicture) {
    if (svgPicture) {
      let svgClear = svgPicture.replace(/\\/g, "").replace("<style>", "");
      this.setState({ image: svgClear });
    }
  }
  widthIconContainer() {
    if (document.getElementById("titleBar")) {
      let heightIconContainer = document.getElementById("titleBar")
        .offsetHeight;
      return heightIconContainer;
    }
  }
  widthIconContainer() {
    if (this.titleBar.current) {
      let heightIconContainer = this.titleBar.current.offsetHeight;
      return heightIconContainer;
    }
  }
  render() {
    const divStyle = {
      boxShadow: " 5px 10px 30px rgba(0,0,0,.2)",
      overflow: "hidden",
    };
    const iconContainer = {
      padding: "8px",
      minWidth: this.state.actualHeight * 10 + "px",
      backgroundColor: this.props.item.backgroundIcon,
      fill: this.props.item.color,
    };

    const titleStyle = {
      width: "max-content",
      minWidth: "100%",
      color: this.props.item.textColor,
      backgroundColor: this.props.item.backgroundText,
      fontSize: this.props.item.textSize + "px",
      display: "block",
      justifyContent: "center",
      fontFamily: this.props.item.textFont,
    };

    const imageVector = {
      height: "100%",
      display: "flex",
      alignContent: "center",
    };

    const imageRaw = {
      width: "100%",
      objectFit: "contain",
      height: "100%",
    };

    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator

    return (
      <>
        <div
          className="container_component "
          style={divStyle}
          ref={this.titleBar}
        >
          <div className="icon_container resizeSVG" style={iconContainer}>
            {!pattern.test(this.state.image) && (
              <div
                dangerouslySetInnerHTML={{ __html: this.state.image }}
                style={imageVector}
              />
            )}
            {!!pattern.test(this.state.image) && (
              <img
                src={this.state.image}
                width="80"
                height="80"
                style={imageRaw}
                alt="logo"
              />
            )}
          </div>
          <div className="text_title" style={titleStyle}>
            <h1>{this.state.text}</h1>
          </div>
        </div>
      </>
    );
  }
}

export default TitleBar;
