import Item from './Item';

class FoodGroupModel extends Item {

    constructor(i, x, maxH, maxW, minH, minW, widthSize, heightSize, background, alpha, id, colorPrimary, colorAccent, fontFamily, fontFamilySecondary,
        fontSizeTitle, fontSizeTitleNi, fontSizeTitleSec, fontSizeTitleSecNi, fontSizeDescription, fontSizeDescriptionNi, fontSizeDescriptionSec, fontSizeDescriptionSecNi, fontSizeOrigin, fontSizeCalories,
        fontSizePrice, colorPrice, colorPriceTx, fontFamilyPrice,headerImage, typeSize) {

        super(i, x, maxH, maxW, minH, minW);
        this.background = background;
        this.alpha = alpha;
        this.widthSize = widthSize;
        this.heightSize = heightSize;
        this.id = id;
        this.colorPrimary = colorPrimary;
        this.colorAccent = colorAccent;
        this.fontFamily = fontFamily;
        this.fontFamilySecondary = fontFamilySecondary;
        this.fontSizeTitle = fontSizeTitle;
        this.fontSizeTitleNi = fontSizeTitleNi;
        this.fontSizeTitleSec = fontSizeTitleSec;
        this.fontSizeTitleSecNi = fontSizeTitleSecNi;
        this.fontSizeDescription = fontSizeDescription;
        this.fontSizeDescriptonNi = fontSizeDescriptionNi;
        this.fontSizeDescriptionSec = fontSizeDescriptionSec;
        this.fontSizeDescriptionSecNi = fontSizeDescriptionSecNi;
        this.fontSizeOrigin = fontSizeOrigin;
        this.fontSizeCalories = fontSizeCalories;
        this.fontSizePrice = fontSizePrice;
        this.colorPrice = colorPrice;
        this.colorPriceTx = colorPriceTx;
        this.fontFamilyPrice = fontFamilyPrice;
        this.headerImage = headerImage;
        this.typeSize = typeSize;
        
        this.w = this.widthSize;
        this.h = this.heightSize;
    }

}

export default FoodGroupModel;