import React from "react";
import {
  Button,
  Modal,
  Form,
  Image,
  Col,
  Row,
  ButtonToolbar,
  ButtonGroup,
  InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarker,
  faPencilAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { getfooditemsbyfoodgroupID } from "../../api/Api";
import * as allergensIcons from "../../styles/allergens";
import FontList from "../../styles/FontList";
import UploadImage from "./commons/UploadImage";
import ImageFoodGroup from "./foodGroupComponent/ImageFoodGroup";

const listFont = FontList;

class FoodGroup_Port extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_delete: false,
      show_modify: false,
      color_bg: this.props.item.background,
      color_bg_canc: this.props.item.background,
      width_size: this.props.item.widthSize,
      height_size: this.props.item.heightSize,
      type_size: this.props.item.typeSize,
      zindex_bg: this.props.item.zIndex,
      zindex_bg_canc: this.props.item.zIndex,
      foodgroup: [],
      id_foodgroup: this.props.item.id,
      foodgroup_validation: "",
      switchbackground: true,

      headerImage: this.props.item.headerImage,
      headerImage_canc: this.props.item.headerImage,
      //Color
      color_primary: this.props.item.colorPrimary,
      color_primary_canc: this.props.item.colorPrimary,
      color_accent: this.props.item.colorAccent,
      color_accent_canc: this.props.item.colorAccent,

      //Font Family
      fontFamily_primary: this.props.item.fontFamily,
      fontFamily_primary_canc: this.props.item.fontFamily,
      fontFamily_secondary: this.props.item.fontFamilySecondary,
      fontFamily__secondary_canc: this.props.item.fontFamilySecondary,

      //Title
      fontSize_title: this.props.item.fontSizeTitle,
      fontSize_title_canc: this.props.item.fontSizeTitle,
      fontSize_titleNi: this.props.item.fontSizeTitleNi,
      fontSize_titleNi_canc: this.props.item.fontSizeTitleNi,
      fontSize_titleSec: this.props.item.fontSizeTitleSec,
      fontSize_titleSec_canc: this.props.item.fontSizeTitleSec,
      fontSize_titleSecNi: this.props.item.fontSizeTitleSecNi,
      fontSize_titleSecNi_canc: this.props.item.fontSizeTitleSecNi,

      //Description
      fontSize_description: this.props.item.fontSizeDescription,
      fontSize_description_canc: this.props.item.fontSizeDescription,
      fontSize_descriptionNi: this.props.item.fontSizeDescriptionNi,
      fontSize_descriptionNi_canc: this.props.item.fontSizeDescriptionNi,
      fontSize_descriptionSec: this.props.item.fontSizeDescriptionSec,
      fontSize_descriptionSec_canc: this.props.item.fontSizeDescriptionSec,
      fontSize_descriptionSecNi: this.props.item.fontSizeDescriptionSecNi,
      fontSize_descriptionSecNi_canc: this.props.itemfontSizeDescritionSecNi,

      //Origin
      fontSize_origin: this.props.item.fontSizeOrigin,
      fontSize_originn_canc: this.props.item.fontSizeOrigin,
      //Calories
      fontSize_calories: this.props.item.fontSizeCalories,
      fontSize_calories_canc: this.props.item.fontSizeCalories,
      //Price
      fontSize_price: this.props.item.fontSizePrice,
      fontSize_price_canc: this.props.item.fontSizePrice,
      color_priceBg: this.props.item.colorPrice,
      color_priceBg_canc: this.props.item.ColorPrice,
      color_priceTx: this.props.item.colorPriceTx,
      color_priceTx_canc: this.props.item.colorPriceTx,
      fontFamily_price: this.props.item.fontFamilyPrice,
      fontFamily_price_canc: this.props.item.fontFamilyPrice,
    };
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleChangeIDFoodgroup = this.handleChangeIDFoodgroup.bind(this);

    //Color
    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.handleChangeColorPrimary = this.handleChangeColorPrimary.bind(this);
    this.handleChangeColorAccent = this.handleChangeColorAccent.bind(this);
    //Font Family
    this.handleChangeFontFamilyPrimary = this.handleChangeFontFamilyPrimary.bind(
      this
    );
    this.handleChangeFontFamilySecondary = this.handleChangeFontFamilySecondary.bind(
      this
    );

    //Title
    this.handleChangeFontSizeTitle = this.handleChangeFontSizeTitle.bind(this);
    this.handleChangeFontSizeTitleNi = this.handleChangeFontSizeTitleNi.bind(
      this
    );
    this.handleChangeFontSizeTitleSec = this.handleChangeFontSizeTitleSec.bind(
      this
    );
    this.handleChangeFontSizeTitleSecNi = this.handleChangeFontSizeTitleSecNi.bind(
      this
    );

    //Description
    this.handleChangeFontSizeDescription = this.handleChangeFontSizeDescription.bind(
      this
    );
    this.handleChangeFontSizeDescriptionNi = this.handleChangeFontSizeDescriptionNi.bind(
      this
    );
    this.handleChangeFontSizeDescriptionSec = this.handleChangeFontSizeDescriptionSec.bind(
      this
    );
    this.handleChangeFontSizeDescriptionSecNi = this.handleChangeFontSizeDescriptionSecNi.bind(
      this
    );
    //Origin
    this.handleChangeFontSizeOrigin = this.handleChangeFontSizeOrigin.bind(
      this
    );
    //Calories
    this.handleChangeFontSizeCalories = this.handleChangeFontSizeCalories.bind(
      this
    );

    //Price
    this.handleChangeColorPriceTxt = this.handleChangeColorPriceTxt.bind(this);
    this.handleChangeColorPriceBg = this.handleChangeColorPriceBg.bind(this);
    this.handleChangeFontFamilyPrice = this.handleChangeFontFamilyPrice.bind(
      this
    );
    this.handleChangeFontSizePrice = this.handleChangeFontSizePrice.bind(this);
  }
  componentDidMount() {
    this.getFoodItems();
  }
  //MODAL DELETE
  //Close
  handleCloseDelete = () => {
    this.setState({ show_delete: false });
  };
  //Open
  handleShowDelete = () => {
    this.setState({ show_delete: true });
  };
  //MODAL MODIFY
  //Close
  handleCloseModify = () => {
    this.setState({ show_modify: false });
  };
  //Open
  handleShowModify = () => {
    this.setState({ show_modify: true });
  };
  //Change Color Background
  handleChangeColor(event) {
    this.setState({ color_bg: event.target.value });
  }

  //Transparent
  handleswitchBackground = (event) => {
    this.setState({ switchbackground: event.target.checked });
    if (this.state.switchbackground === true) {
      this.setState({ color_bg: "transparent" });
    } else {
      this.setState({ color_bg: this.state.color_bg_canc });
    }
  };

  //Change COLOR
  //Change Color PRIMARY
  handleChangeColorPrimary(event) {
    this.setState({ color_primary: event.target.value });
  }
  //Change Color ACCENT
  handleChangeColorAccent(event) {
    this.setState({ color_accent: event.target.value });
  }

  // Change FONT FAMILY
  //Change Font Family Primary
  handleChangeFontFamilyPrimary(event) {
    this.setState({ fontFamily_primary: event.target.value });
  }

  //Change Font Family Secondary
  handleChangeFontFamilySecondary(event) {
    this.setState({ fontFamily_secondary: event.target.value });
  }

  // TITLE SIZE
  //Change Font SIZE Title
  handleChangeFontSizeTitle(event) {
    // if (event.target.value > 50) {
    //   this.setState({ fontSize_title: 50 });
    // } else if (event.target.value < 25) {
    //   this.setState({ fontSize_title: 25 });
    // } else {
    //   this.setState({ fontSize_title: event.target.value });
    // }

    this.setState({ fontSize_title: event.target.value });
  }
  //Change Font SIZE Title no image
  handleChangeFontSizeTitleNi(event) {
    // if (event.target.value > 55) {
    //   this.setState({ fontSize_titleNi: 5 });
    // } else if (event.target.value < 15) {
    //   this.setState({ fontSize_titleNi: 15 });
    // } else {
    //   this.setState({ fontSize_titleNi: event.target.value });
    // }

    this.setState({ fontSize_titleNi: event.target.value });
  }

  //Change Font SIZE Title 2
  handleChangeFontSizeTitleSec(event) {
    // if (event.target.value > 50) {
    //   this.setState({ fontSize_titleSec: 50 });
    // } else if (event.target.value < 25) {
    //   this.setState({ fontSize_titleSec: 25 });
    // } else {
    //   this.setState({ fontSize_titleSec: event.target.value });
    // }

    this.setState({ fontSize_titleSec: event.target.value });
  }

  //Change Font SIZE Title 2 no image
  handleChangeFontSizeTitleSecNi(event) {
    // if (event.target.value > 55) {
    //   this.setState({ fontSize_titleSecNi: 55 });
    // } else if (event.target.value < 30) {
    //   this.setState({ fontSize_titleSecNi: 30 });
    // } else {
    //   this.setState({ fontSize_titleSecNi: event.target.value });
    // }

    this.setState({ fontSize_titleSecNi: event.target.value });
  }

  // DESCRIPTION
  //Change Font SIZE Description
  handleChangeFontSizeDescription(event) {
    // if (event.target.value > 30) {
    //   this.setState({ fontSize_description: 30 });
    // } else if (event.target.value < 20) {
    //   this.setState({ fontSize_description: 20 });
    // } else {
    //   this.setState({ fontSize_description: event.target.value });
    // }

    this.setState({ fontSize_description: event.target.value });
  }

  //Change Font SIZE Description No image
  handleChangeFontSizeDescriptionNi(event) {
    // if (event.target.value > 33) {
    //   this.setState({ fontSize_descriptionNi: 33 });
    // } else if (event.target.value < 22) {
    //   this.setState({ fontSize_descriptionNi: 2 });
    // } else {
    //   this.setState({ fontSize_descriptionNi: event.target.value });
    // }

    this.setState({ fontSize_descriptionNi: event.target.value });
  }

  //Change Font SIZE Description 2
  handleChangeFontSizeDescriptionSec(event) {
    // if (event.target.value > 30) {
    //   this.setState({ fontSize_descriptionSec: 30 });
    // } else if (event.target.value < 20) {
    //   this.setState({ fontSize_descriptionSec: 20 });
    // } else {
    //   this.setState({ fontSize_descriptionSec: event.target.value });
    // }

    this.setState({ fontSize_descriptionSec: event.target.value });
  }

  //Change Font SIZE Description 2 No image
  handleChangeFontSizeDescriptionSecNi(event) {
    // if (event.target.value > 33) {
    //   this.setState({ fontSize_descriptionSecNi: 33 });
    // } else if (event.target.value < 22) {
    //   this.setState({ fontSize_descriptionSecNi: 22 });
    // } else {
    //   this.setState({ fontSize_descriptionSecNi: event.target.value });
    // }

    this.setState({ fontSize_descriptionSecNi: event.target.value });
  }

  // Origin SIZE
  //Change Font SIZE Origin
  handleChangeFontSizeOrigin(event) {
    // if (event.target.value > 40) {
    //   this.setState({ fontSize_origin: 40 });
    // } else if (event.target.value < 20) {
    //   this.setState({ fontSize_origin: 20 });
    // } else {
    //   this.setState({ fontSize_origin: event.target.value });
    // }

    this.setState({ fontSize_origin: event.target.value });
  }

  // Calories SIZE
  //Change Font SIZE Calories
  handleChangeFontSizeCalories(event) {
    if (event.target.value > 40) {
      this.setState({ fontSize_calories: 40 });
    } else if (event.target.value < 15) {
      this.setState({ fontSize_calories: 15 });
    } else {
      this.setState({ fontSize_calories: event.target.value });
    }
  }

  // PRICE
  //Change Font SIZE PRICE
  handleChangeFontSizePrice(event) {
    if (event.target.value > 55) {
      this.setState({ fontSize_price: 55 });
    } else if (event.target.value < 20) {
      this.setState({ fontSize_price: 20 });
    } else {
      this.setState({ fontSize_price: event.target.value });
    }
  }

  //Change Font Family Price
  handleChangeFontFamilyPrice(event) {
    this.setState({ fontFamily_price: event.target.value });
  }

  //Cahnge Color PRICE Text
  handleChangeColorPriceTxt(event) {
    this.setState({ color_priceTx: event.target.value });
  }
  //Cahnge Color PRICE Background
  handleChangeColorPriceBg(event) {
    this.setState({ color_priceBg: event.target.value });
  }

  //Change Index
  handleChangeIndex(event) {
    if (event.target.value > 999) {
      this.setState({ zindex_bg: 999 });
    } else if (event.target.value < 1) {
      this.setState({ zindex_bg: 1 });
    } else {
      this.setState({ zindex_bg: event.target.value });
    }
    this.props.onLayerChange(this.props.item.i, event.target.value);
  }

  //Cancel Changes
  cancelOnModal = () => {
    //COLOR
    //Color background
    this.setState({ color_bg: this.state.color_bg_canc });
    //ImageUploaded Header
    this.setState({ headerImage: this.state.headerImage_canc });
    //Primary
    this.setState({ color_primary: this.state.color_primary_canc });
    //Accent
    this.setState({
      color_accent: this.state.color_accent_canc,
    });

    //FONTFAMILY
    //Primary
    this.setState({ fontFamily_primary: this.state.fontFamily_primary_canc });
    //Secondary
    this.setState({
      fontFamily_secondary: this.state.fontFamily_secondary_canc,
    });

    //FONTSIZE
    //Title 1
    this.setState({ fontSize_title: this.state.fontSize_title_canc });
    //Title 1 no image
    this.setState({ fontSize_titleNi: this.state.fontSize_titleNi_canc });
    //Title 2
    this.setState({ fontSize_titleSec: this.state.fontSize_titleSec_canc });
    //Title 2 No image
    this.setState({ fontSize_titleSecNi: this.state.fontSize_titleSecNi_canc });

    //Description 1
    this.setState({
      fontSize_description: this.state.fontSize_description_canc,
    });
    //Description 1 No image
    this.setState({
      fontSize_descriptionNi: this.state.fontSize_descriptionNi_canc,
    });
    //Description 2
    this.setState({
      fontSize_descriptionSec: this.state.fontSize_descriptionSec_canc,
    });
    //Description 2  No image
    this.setState({
      fontSize_descriptionSecNi: this.state.fontSize_descriptionSecNi_canc,
    });

    //Z index
    this.setState({ zindex_bg: this.state.zindex_bg_canc });
    // this.props.onLayerChange(this.props.item.i, this.state.zindex_bg_canc);
    this.handleCloseModify();
  };

  confirmChangeonModal = () => {
    //COLOR
    //Color background
    this.setState({ color_bg_canc: this.state.color_bg });
    this.props.item.background = this.state.color_bg;
    //ImageUploaded Header
    this.setState({ headerImage_canc: this.state.headerImage });
    this.props.item.headerImage = this.state.headerImage;
    //Primary
    this.setState({ color_primary_canc: this.state.color_primary });
    this.props.item.colorPrimary = this.state.color_primary;
    //Accent
    this.setState({
      color_accent_canc: this.state.color_accent,
    });
    this.props.item.colorAccent = this.state.color_accent;

    //FONTFAMILY
    //Primary
    this.setState({ fontFamily_primary_canc: this.state.fontFamily_primary });
    this.props.item.fontFamily = this.state.fontFamily_primary;
    //Secondary
    this.setState({
      fontFamily_secondary_canc: this.state.fontFamily_secondary,
    });
    this.props.item.fontFamilySecondary = this.state.fontFamily_secondary;

    //FONTSIZE
    //Title 1
    this.setState({ fontSize_title_canc: this.state.fontSize_title });
    this.props.item.fontSizeTitle = this.state.fontSize_title;
    //Title 1 no image
    this.setState({ fontSize_titleNi_canc: this.state.fontSize_titleNi });
    this.props.item.fontSizeTitleNi = this.state.fontSize_titleNi;
    //Title 2
    this.setState({ fontSize_titleSec_canc: this.state.fontSize_titleSec });
    this.props.item.fontSizeTitleSec = this.state.fontSize_titleSec;
    //Title 2 No image
    this.setState({ fontSize_titleSecNi_canc: this.state.fontSize_titleSecNi });
    this.props.item.fontSizeTitleSecNi = this.state.fontSize_titleSecNi;

    //Description 1
    this.setState({
      fontSize_description_canc: this.state.fontSize_description,
    });
    this.props.item.fontSizeDescription = this.state.fontSize_description;
    //Description 1 No image
    this.setState({
      fontSize_descriptionNi_canc: this.state.fontSize_descriptionNi,
    });

    this.props.item.fontSizeDescriptionNi = this.state.fontSize_descriptionNi;
    //Description 2
    this.setState({
      fontSize_descriptionSec_canc: this.state.fontSize_descriptionSec,
    });
    this.props.item.fontSizeDescriptionSec = this.state.fontSize_descriptionSec;
    //Description 2  No image
    this.setState({
      fontSize_descriptionSecNi_canc: this.state.fontSize_descriptionSecNi,
    });
    this.props.item.fontSizeDescriptionSecNi = this.state.fontSize_descriptionSecNi;
    //Price
    this.setState({
      color_priceBg_canc: this.state.color_priceBg,
    });
    this.props.item.colorPrice = this.state.color_priceBg;
    this.setState({
      color_priceTx_canc: this.state.color_priceTx,
    });
    this.props.item.colorPriceTx = this.state.color_priceTx;
    this.setState({
      fontSize_price_canc: this.state.fontSize_price,
    });
    this.props.item.fontSizePrice = this.state.fontSize_price;
    this.setState({
      fontFamily_price_canc: this.state.fontFamily_price,
    });
    this.props.item.fontFamilyPrice = this.state.fontFamily_price;

    //Origin
    this.setState({
      fontSize_origin_canc: this.state.fontSize_origin,
    });
    this.props.item.fontSizeOrigin = this.state.fontSize_origin;

    //Calories
    this.setState({
      fontSize_calories_canc: this.state.fontSize_calories,
    });
    this.props.item.fontSizeCalories = this.state.fontSize_calories;

    //Z-index
    this.setState({ zindex_bg_canc: this.state.zindex_bg });

    this.props.item.widthSize = this.state.width_size;
    this.props.item.heightSize = this.state.height_size;
    //this.props.item.typeSize = this.state.type_size;

    this.setState({ id_foodgroup_canc: this.state.id_foodgroup });
    this.props.item.id = this.state.id_foodgroup;

    this.props.onStyleChange(this.props.item);
    this.handleCloseModify();
  };

  //Cahnge SIZE
  changeSize_1 = () => {
    this.setState({ height_size: 180 });
    this.setState({ width_size: 108 });
    this.setState({ type_size: 1 });
    //this.props.item.widhtSize = this.state.width_size
    // this.props.item.heightSize = this.state.height_size
    this.props.item.widthSize = this.state.width_size;
    this.props.item.heightSize = this.state.height_size;
    this.props.item.typeSize = this.state.type_size;

    this.confirmChangeonModal();
    this.props.onFoodGroupLandSizeChange(this.props.item, 108, 180, 1);
  };
  changeSize_2 = () => {
    this.setState({ height_size: 90 });
    this.setState({ width_size: 108 });
    this.setState({ type_size: 2 });
    //this.props.item.widhtSize = this.state.width_size
    //this.props.item.heightSize = this.state.height_size
    this.props.item.widthSize = this.state.width_size;
    this.props.item.heightSize = this.state.height_size;
    this.props.item.typeSize = this.state.type_size;
    this.confirmChangeonModal();
    this.props.onFoodGroupLandSizeChange(this.props.item, 108, 90, 2);
  };

  changeSize_3 = () => {
    this.setState({ height_size: 60 });
    this.setState({ width_size: 108 });
    this.setState({ type_size: 3 });
    //this.props.item.widhtSize = this.state.width_size
    //this.props.item.heightSize = this.state.height_size

    this.props.item.widthSize = this.state.width_size;
    this.props.item.heightSize = this.state.height_size;
    this.props.item.typeSize = this.state.type_size;
    this.confirmChangeonModal();
    this.props.onFoodGroupLandSizeChange(this.props.item, 108, 60, 3);
  };
  changeSize_4 = () => {
    this.setState({ height_size: 45 });
    this.setState({ width_size: 108 });
    this.setState({ type_size: 4 });
    //this.props.item.widhtSize = this.state.width_size
    // this.props.item.heightSize = this.state.height_size

    this.props.item.widthSize = this.state.width_size;
    this.props.item.heightSize = this.state.height_size;
    this.props.item.typeSize = this.state.type_size;
    this.confirmChangeonModal();
    this.props.onFoodGroupLandSizeChange(this.props.item, 108, 45, 4);
  };

  //Change ID ZONE
  handleChangeIDFoodgroup(event) {
    this.setState({ id_foodgroup: event.target.value });
  }

  //get MAP FROM DISPLAY ID
  getFoodItems = () => {
    this.setState({ foodgroup: [] });
    this.setState({ foodgroup_validation: "" });
    getfooditemsbyfoodgroupID(this.state.id_foodgroup).then((res) => {
      if (res == null) {
        this.setState({ foodgroup: [] });
        this.setState({ foodgroup_validation: "Food Group empty" });
      } else if (res[0]) {
        this.setState({ foodgroup: res });
        this.setState({ foodgroup_validation: "" });
      } else {
        this.setState({ foodgroup: [] });
        this.setState({ foodgroup_validation: "l'iD zone don't exist" });
      }
    });
  };

  //get Icon Allergens
  getIconSvg(allergen) {
    let allergenIcon = allergen
      .split(" ")
      .join("")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (allergen) {
      const svgIndex = "Svg" + allergenIcon;
      return allergensIcons[svgIndex];
    }
    return null;
  }

  //get WIDTH FLEX
  getwidthItems() {
    let activeFoodItem = this.state.foodgroup.filter(function (value) {
      return value.active !== false;
    }).length;
    let flex = " 25%";
    if (this.state.type_size == 1) {
      if (activeFoodItem > 4) {
        flex = " 30%";
      } else if (activeFoodItem <= 4) {
        flex = " 47%";
      }
    } else if (this.state.type_size == 2) {
      flex = " 44%";
    } else if (this.state.type_size == 3) {
      flex = " 44%";
    } else if (this.state.type_size == 4) {
      flex = " 43%";
    }
    return flex;
  }

  //get max number of Food item
  getmaxFoodItem() {
    let slice = 8;
    if (this.state.type_size == 1) {
      slice = 8;
    } else if (this.state.type_size == 2) {
      slice = 4;
    } else if (this.state.type_size == 3) {
      slice = 2;
    } else if (this.state.type_size == 4) {
      slice = 2;
    }

    return slice;
  }
  getImageUploaded = (ImageUploaded) => {
    this.setState({ headerImage: ImageUploaded })
  }
  //Capitalize
  capitalize(s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }
  render() {
    const divStyle = {
      height: "100%",
      width: "100%",
      transition: "all 250ms ease",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };

    const containerStyle = {
      padding: "10px",
      backgroundColor: this.state.color_bg,
      height: "100%",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
    };

    const foodItemStyle = {
      position: "relative",
      height: "400px",
      backgroundColor: "#FFFFFF",
      padding: "0",
      margin: "auto 30px",
      flex: "1 1" + this.getwidthItems(),
    };

    const originStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.state.fontFamily_secondary,
      color: this.state.color_accent,
      fontSize: this.state.fontSize_origin + "px",
    };
    const titleStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.state.fontFamily_primary,
      color: this.state.color_primary,
      fontSize: this.state.fontSize_title + "px",
    };

    const titleNIStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.state.fontFamily_primary,
      color: this.state.color_primary,
      fontSize: this.state.fontSize_titleNi + "px",
    };
    const titleSecStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.state.fontFamily_primary,
      color: this.state.color_primary,
      fontSize: this.state.fontSize_titleSec + "px",
    };
    const titleSecNIStyle = {
      lineHeight: "1.1",
      fontWeight: "bold",
      fontFamily: this.state.fontFamily_primary,
      color: this.state.color_primary,
      fontSize: this.state.fontSize_titleSecNi + "px",
    };

    const descriptionStyle = {
      lineHeight: "1.1",
      fontFamily: this.state.fontFamily_primary,
      color: this.state.color_primary,
      fontSize: this.state.fontSize_description + "px",
    };

    const descriptionNIStyle = {
      lineHeight: "1.1",
      fontFamily: this.state.fontFamily_primary,
      color: this.state.color_primary,
      fontSize: this.state.fontSize_descriptionNi + "px",
    };
    const descriptionSecStyle = {
      lineHeight: "1.1",
      fontFamily: this.state.fontFamily_primary,
      color: this.state.color_primary,
      fontSize: this.state.fontSize_descriptionSec + "px",
    };
    const descriptionSecNIStyle = {
      lineHeight: "1.1",
      fontFamily: this.state.fontFamily_primary,
      color: this.state.color_primary,
      fontSize: this.state.fontSize_descriptionSecNi + "px",
    };
    const containerLabelStyle = {
      position: "relative",
      alignSelf: "flex-end",
      top: "-40px",
      right: "5px",
      display: "flex",
      width: "100%",
      justifyContent: "end",
      height: 0,
    };
    const tagImageStyle = {
      backgroundColor: "#F6F6F6",
      borderRadius: "100%",
      objectFit: "cover",
      width: "60px",
      height: "60px",
      float: "left",
      margin: "0 3px",
    };

    const bodyStyle = {
      flexDirection: "column",
      display: "flex",
      position: "relative",
      width: "100%",
    };
    const bodyStyleNi = {
      flexDirection: "column",
      display: "flex",
      position: "relative",
      width: "100%",
      minHeight: "100%",
    };
    const overflowStyle = {
      //overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "0 8px",
    };
    const allergyContStyle = {
      //display: "block",
      padding: "0 5px",
      display: "flex",
      flexWrap: "wrap",
      paddingLeft: 0,
    };
    const allergyIconStyle = {
      fill: this.state.color_accent,
      textAlign: "center",
      width: "30px",
      margin: "3px 0",
    };

    const caloriesStyle = {
      marginBottom: "0",
      lineHeight: "1.1",
      color: this.state.color_accent,
      fontSize: this.state.fontSize_calories + "px",
      fontFamily: this.state.fontFamily_secondary,
    };

    const containerPricesStyle = {
      position: "absolute",
      bottom: "110px",
      right: "-20px",
      backgroundColor: this.state.color_priceBg,
      color: this.state.color_priceTx,
      maxWidth: "170px",
      border: "1px solid #fff",
      textAlign: "center",
      padding: "5px",
    };

    const pricesStyle = {
      marginBottom: 0,
      padding: "5px",
      textAlign: "center",
      fontFamily: this.state.fontFamily_price,
      color: this.state.color_priceTx,
      fontSize: this.state.fontSize_price + "px",
    };
    const containerfoodItemStyle = {
      height: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
    };
    return (
      <>
        <div style={divStyle}>
          <Button
            variant="primary"
            className="circlebutton"
            onClick={this.handleShowModify}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
          <Button
            className="circlebutton"
            variant="danger"
            onClick={this.handleShowDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>

          <div style={containerStyle}>
            {this.state.foodgroup[0] &&
              this.state.foodgroup
                .filter(function (value) {
                  return value.active !== false;
                })
                .slice(0, this.getmaxFoodItem())
                .map((fooditem, i) => {
                  return (
                    <div style={foodItemStyle} className="newshadow">
                      <div style={containerfoodItemStyle}>
                        {fooditem.config.isUsingImage === true &&
                          <ImageFoodGroup
                            ImageUploaded={this.state.headerImage}
                            foodItemTemplate={fooditem}
                            foodItemImage={fooditem.template.picto}
                            id_foodgroup={this.state.id_foodgroup}
                            color_accent={this.state.color_accent}
                          />}
                        <div
                          style={
                            fooditem.config && fooditem.config.isUsingImage === false
                              ? bodyStyleNi
                              : bodyStyle
                          }
                        >
                          <div style={overflowStyle}>
                            <div className="containerCardFg">
                              {!fooditem.tags
                                ? null
                                : fooditem.tags
                                  .filter(function (value) {
                                    return value.tag.kind == "ORIGIN";
                                  })
                                  .map(function (tag, i) {
                                    return (
                                      <h4
                                        style={originStyle}
                                        className="itemCardFg"
                                      >
                                        <FontAwesomeIcon
                                          icon={faMapMarker}
                                          className="mr-2"
                                        />
                                        {tag.tag.name}
                                      </h4>
                                    );
                                  })}

                              {!fooditem.name ? null : (
                                <h3
                                  className="itemCardFg"
                                  style={
                                    fooditem.config.isUsingImage === false
                                      ? titleNIStyle
                                      : titleStyle
                                  }
                                >
                                  {fooditem.name}
                                </h3>
                              )}
                              {!fooditem.name_secondary ? null : (
                                <h3
                                  className="itemCardFg"
                                  style={
                                    fooditem.config.isUsingImage === false
                                      ? titleSecNIStyle
                                      : titleSecStyle
                                  }
                                >
                                  {fooditem.name_secondary}
                                </h3>
                              )}
                              {!fooditem.description ? null : (
                                <h3
                                  className="itemCardFg"
                                  style={
                                    fooditem.config.isUsingImage === false
                                      ? descriptionNIStyle
                                      : descriptionStyle
                                  }
                                >
                                  {fooditem.description}
                                </h3>
                              )}
                              {!fooditem.description_secondary ? null : (
                                <h3
                                  className="itemCardFg"
                                  style={
                                    fooditem.config.isUsingImage === false
                                      ? descriptionSecNIStyle
                                      : descriptionSecStyle
                                  }
                                >
                                  {fooditem.description_secondary}
                                </h3>
                              )}
                              {!fooditem.calories ? null : (
                                <h4
                                  className="itemCardFg"
                                  style={caloriesStyle}
                                >
                                  Calories: {fooditem.calories} kcal
                                </h4>
                              )}

                              {!fooditem.tags ? null : (
                                <div
                                  className="itemCardFg"
                                  style={allergyContStyle}
                                >
                                  {fooditem.tags
                                    .filter(function (value) {
                                      return value.tag.kind == "ALLERGEN";
                                    })
                                    .map((item) => {
                                      return (
                                        <h3 style={allergyIconStyle}>
                                          {this.getIconSvg(item.tag.name)}
                                        </h3>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={containerLabelStyle}>
                        {!fooditem.tags
                          ? null
                          : fooditem.tags
                            .slice(0, 3)
                            .filter(function (value) {
                              return value.tag.kind == "LABEL";
                            })
                            .map(function (tag, i) {
                              return (
                                <Image
                                  className="newshadow"
                                  src={tag.tag.image}
                                  style={tagImageStyle}
                                />
                              );
                            })}
                      </div>
                      {fooditem.prices == null ? null : (
                        fooditem.prices[0].value == 0 || fooditem.prices[0].value == null ? null : (
                          <div
                            style={containerPricesStyle}
                            className="newshadow "
                          >
                            <h3 style={pricesStyle}>
                              {fooditem.prices[0].value}
                              {fooditem.prices[0].currency === " " ||
                                fooditem.prices[0].currency === "" ||
                                fooditem.prices[0].currency === "EUR" ||
                                fooditem.prices[0].currency === "Eur" ||
                                fooditem.prices[0].currency === "eur" ||
                                fooditem.prices[0].currency === "Euro"
                                ? "€"
                                : this.capitalize(
                                  fooditem.prices[0].currency
                                )}
                            </h3>
                          </div>
                        )
                      )}

                    </div>
                  );
                })}
          </div>
        </div>
        {/* MODAL MODIFY */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            fade={false}
            animation={false}
            className="sideMenu"
            show={this.state.show_modify}
            onHide={this.cancelOnModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName="backdrop_sideMenu"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Foodgroup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* FOODGROUP ID*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>FoodGroup Id</Form.Label>
                      <Col md={6}>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            value={this.state.id_foodgroup}
                            onChange={this.handleChangeIDFoodgroup}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={this.getFoodItems}
                            >
                              Ok
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          FoodGroup:
                          <h6
                            className={
                              this.state.foodgroup_validation ==
                              "l'iD Food Group don't exist" && "warning"
                            }
                          >
                            {this.state.foodgroup_validation}
                          </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FOODGROUP Image*/}
                <UploadImage
                  imageUploadedToParent={this.getImageUploaded}
                  imageFromParent={this.state.headerImage}
                />

                {/* SELECT SIZE*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Select Size</Form.Label>
                      <Col>
                        <ButtonToolbar aria-label="Toolbar with button groups">
                          <ButtonGroup
                            className="mr-2"
                            aria-label="First group"
                          >
                            <Button onClick={this.changeSize_1}>1</Button>
                            <Button onClick={this.changeSize_2}>2</Button>
                            <Button onClick={this.changeSize_3}>3</Button>
                            <Button onClick={this.changeSize_4}>4</Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* SELECT  COLOR*/}
                {/* Color Background*/}
                <Row>
                  <Col md={12} className="my-2">
                    <Form.Check
                      checked={this.state.switchbackground}
                      onChange={this.handleswitchBackground}
                      type="switch"
                      id="custom-switch"
                      label="Background Color"
                    />
                  </Col>

                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColor}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColor}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Color Primary */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Primary Color </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_primary}
                          onChange={this.handleChangeColorPrimary}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_primary}
                          onChange={this.handleChangeColorPrimary}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_primary}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Color Accent */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Accent Color</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_accent}
                          onChange={this.handleChangeColorAccent}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_accent}
                          onChange={this.handleChangeColorAccent}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_accent}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FONTFAMILY */}
                {/* FONTFAMILY Primary*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family Primary</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamilyPrimary}
                          value={this.state.fontFamily_primary}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FONTFAMILY  Secondary*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family Secondary</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamilySecondary}
                          value={this.state.fontFamily_secondary}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Title */}
                {/* FONT SIZE  Title 1  QUI ANGELO*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Title 1 Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontSize_title}
                          onChange={this.handleChangeFontSizeTitle}
                          min="25"
                          max="500"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontSize_title}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FONT SIZE  Title 1 No image */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Title 1 Font size No image</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontSize_titleNi}
                          onChange={this.handleChangeFontSizeTitleNi}
                          min="25"
                          max="500"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontSize_titleNi}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FONT SIZE  Title 2  */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Title 2 Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontSize_titleSec}
                          onChange={this.handleChangeFontSizeTitleSec}
                          min="25"
                          max="500"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontSize_titleSec}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FONT SIZE  Title 2 No image */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Title 2 Font size No image</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontSize_titleSecNi}
                          onChange={this.handleChangeFontSizeTitleSecNi}
                          min="25"
                          max="500"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontSize_titleSecNi}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Description */}
                {/* FONT SIZE Description 1 */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Description Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontSize_description}
                          onChange={this.handleChangeFontSizeDescription}
                          min="20"
                          max="500"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontSize_description}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FONT SIZE Description 1 No image */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Description 1 No image Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontSize_descriptionNi}
                          onChange={this.handleChangeFontSizeDescriptionNi}
                          min="22"
                          max="500"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontSize_descriptionNi}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FONT SIZE Description 2 */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Description 2 Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontSize_descriptionSec}
                          onChange={this.handleChangeFontSizeDescriptionSec}
                          min="20"
                          max="500"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontSize_descriptionSec}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FONT SIZE Description 2 No image*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Description 2 No Image Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontSize_descriptionSecNi}
                          onChange={this.handleChangeFontSizeDescriptionSecNi}
                          min="22"
                          max="500"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontSize_descriptionSecNi}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Origin FONT SIZE */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Origin Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontSize_origin}
                          onChange={this.handleChangeFontSizeOrigin}
                          min="20"
                          max="500"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontSize_origin}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Calories FONT SIZE */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Calories Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontSize_calories}
                          onChange={this.handleChangeFontSizeCalories}
                          min="23"
                          max="500"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontSize_calories}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* PRICE */}
                {/* Color BACKGROUND */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Background Color Price </Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_priceBg}
                          onChange={this.handleChangeColorPriceBg}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_priceBg}
                          onChange={this.handleChangeColorPriceBg}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_priceBg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Color Text */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Text Color Price</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_priceTx}
                          onChange={this.handleChangeColorPriceTxt}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_priceTx}
                          onChange={this.handleChangeColorPriceTxt}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_priceTx}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FONTFAMILY */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family Price</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamilyPrice}
                          value={this.state.fontFamily_price}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/*  FONT SIZE */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font size Price</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontSize_price}
                          onChange={this.handleChangeFontSizePrice}
                          min="33"
                          max="47"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontSize_price}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Z INDEX*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Z-Index</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.zindex_bg}
                          onChange={this.handleChangeIndex}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          z-index: {this.state.zindex_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                    <p>"No image" refers to the text size a food item has when the Image Switch in Aldebaran is off</p>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.cancelOnModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.confirmChangeonModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL DELETE */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            show={this.state.show_delete}
            onHide={this.handleCloseDelete}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Food Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>wonna delete?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseDelete}>
                Close
              </Button>
              <Button
                onClick={() => this.props.onRemoveItem(this.props.item.i)}
                variant="danger"
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default FoodGroup_Port;
