import './App.css';
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import EditorLandscape from './pages/EditorLandscape';
import EditorPortrait from './pages/EditorPortrait';
import Home from './pages/Home';
import Login from './pages/Login';
import SelectScreenType from './pages/SelectScreenType'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import PreviewLandscape from './pages/PreviewLandscape'
import PreviewPortrait from './pages/PreviewPortrait'

import './styles/FontList.css'

function App() {
  return (
    <Router>
    <Switch>
    <Route path="/previewlandscape">
          <PreviewLandscape />
        </Route>
      <Route path="/previewportrait">
          <PreviewPortrait />
        </Route>
        <Route path="/editorlandscape">
          <EditorLandscape />
        </Route>
        <Route path="/editorportrait">
          <EditorPortrait />
        </Route>
        <Route path="/selectscreen">
          <SelectScreenType />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/">
          <Login />
        </Route>
        
    </Switch>
  </Router>
  );
}

export default App;
