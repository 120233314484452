import React from "react";
import { Carousel, Image } from "react-bootstrap";
import { getPromotionsByTag } from "../../api/Api";
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed";

class Promotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagSelected: this.props.item.tag,
      promos: [],
      textPromo: [],
      actualWidth: 0,
      carouseleInterval:1000,
    };
  }

  //Get Promo
  componentDidMount() {
    this.getPromo();
    this.setWidth();
       // set Interval
       this.interval = setInterval(this.getPromo.bind(this), 60*10000);
  }
  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }
  setWidth() {
    this.props.layout.forEach((element) => {
      if (element.i === this.props.item.i) {
        this.setState({ actualWidth: element.w * 10 });
      }
    });
  }

  //GET PROMO
  getPromo = () => {
    getPromotionsByTag(this.props.currentDisplay, this.state.tagSelected).then(
      (res) => {
        if (res == null) {
          this.setState({ promos: [] });
        } else if (res[0]) {
        //  this.setState({ promos: res });
          this.filterPromoDisabled(res)
          this.getTextPromo();
        }
      }
    );
  };

  getTextPromo = () => {
    let textPromofetch = [];
    var context = this;
    this.state.promos
      .filter(function (value) {
        return value.conf.text_file !== "";
      })
      .map(function (item, i) {
        const url = item.conf.text_file;

        return fetch(url)
          .then((response) => response.json())
          .then((jsonData) => {
            textPromofetch.push(jsonData);
            context.setState({ textPromo: textPromofetch });
          });
      });
  };


filterPromoDisabled(promotions){
  let promotionsActive=[];
  
  promotions.map((promotion)=>{
  let isPromoActive = true
  //isPromoActive = !isPromoActive ? false : this.verifyDate(promotion.promotion.starts_at, promotion.promotion.ends_at)
  isPromoActive = !isPromoActive ? false : this.verifyTime(promotion.promotion.conf.starts_time_at, promotion.promotion.conf.ends_time_at)
  isPromoActive = !isPromoActive ? false : this.verifyTrigger(promotion.restaurants)
if(isPromoActive){
  promotionsActive.push(promotion.promotion)
}
})
this.setState({promos:promotionsActive})
}

verifyDate(start, end) {
  const today = new Date()
  start = new Date(start)
  end = new Date(end)

  return start<today && today<end
}

verifyTime(start, end) {
  let now = new Date()
  
  const minutes = now.getMinutes()
  const hours = now.getHours() < 10 ? "0"+now.getHours() : now.getHours()

  start = start.split(":")[0]+""+start.split(":")[0]
  now = hours+""+minutes
  end = end.split(":")[0]+""+end.split(":")[0]

  return start<now && now<end
}

verifyTrigger(restaurants) {
  if(restaurants){
  restaurants.forEach(restaurant => {
      if(restaurant.region_id) {
          if(!restaurant.level_reached) {
              return false
          }
      }
  })

  return true
}
}

videoSlide(){
  if(document.querySelector('.active video')){
    let video= document.querySelector('.active video')
    video.play()
  
  
    video.addEventListener('play', (event) => {
      this.setState({carouseleInterval:null})
    })
  
    video.addEventListener('pause', (event) => {
      this.setState({carouseleInterval:1000})
    });
  
  }else{
    this.setState({carouseleInterval:30000})
  }
  }
  
  
  render() {
    const that = this;
    let arePromoHorizontal =false;
    this.state.promos.forEach(promo=>{
      if(promo.conf.picture_landscape !==""||promo.conf.video_landscape !=="" || promo.conf.text_file !=="")
{arePromoHorizontal= true}
    })
    return (
      <>
        {arePromoHorizontal ? (
          <div className="promo">
            <Carousel controls={false} indicators={false}
              interval={this.state.carouseleInterval}
              onSlid={() => this.videoSlide()}>
              {/* IF IMAGE */}
              {this.state.promos
                .filter(function (value) {
                  return value.conf.picture_landscape;
                })
                .map(function (item, i) {
                  return (
                    <Carousel.Item key={i}>
                      <div className="slide-demo">
                        {/* IMAGE */}
                        <Image
                          src={item.conf.picture_landscape}
                          className="imageCarousel"
                        />
                      </div>
                    </Carousel.Item>
                  );
                })}
              {/*  IF VIDEO*/}
              {this.state.promos
                .filter(function (value) {
                  return value.conf.video_landscape;
                })
                .map(function (item, i) {
                  return (
                    <Carousel.Item key={i}>
                      {/*  IF VIDEO */}
                      <div className="video-section">
                        <ResponsiveEmbed
                          aspectRatio="16by9"
                          className="video-section"
                        >
                          <video class="video-fluid" 
                          autoPlay={that.state.promos.length===1 ? true :false} loop={that.state.promos.length===1 ? true :false}
                           muted>
                            <source src={item.conf.video_landscape} />
                          </video>
                        </ResponsiveEmbed>
                      </div>
                    </Carousel.Item>
                  );
                })}
              {this.props.width}
              {/*  IF TEXT*/}
              {this.state.textPromo.map(function (promo, i) {
                return (
                  <Carousel.Item key={i}>
                    <div
                      className="slide-textpromo"
                      style={{
                        backgroundColor: promo.background.backgroundColor,
                      }}
                    >
                      <h1
                        className="textpromo-title"
                        style={{
                          lineHeight: promo.title.lineHeight,
                          wordBreak: "break-word",
                          color: promo.title.color,
                          fontSize:
                            (that.state.actualWidth *
                                promo.title.fontSize) /
                              promo.background.widthContainer +
                            `px`,
                          fontFamily: promo.title.fontFamily,
                          fontWeight: promo.title.bold,
                          fontStyle: promo.title.italic,
                          textDecoration:
                            promo.title.underline + promo.title.lineThrough,
                          paddingLeft:
                          (that.state.actualWidth *
                            promo.title.paddingLeftRight) /
                          promo.background.widthContainer +
                            `px`,
                          paddingRight:
                          (that.state.actualWidth *
                            promo.title.paddingLeftRight) /
                          promo.background.widthContainer +
                            `px`,
                          marginBottom:
                          (that.state.actualWidth *
                            promo.title.marginBttm) /
                          promo.background.widthContainer +
                            `px`,
                        }}
                      >
                        {promo.title.text}
                      </h1>
                      <p
                        className="textpromo-title"
                        style={{
                          lineHeight: promo.subtitle.lineHeight,
                          wordBreak: "break-word",
                          color: promo.subtitle.color,
                          fontSize:
                          (that.state.actualWidth *
                            promo.subtitle.fontSize) /
                          promo.background.widthContainer +
                            `px`,
                          fontWeight: promo.subtitle.bold,
                          fontFamily: promo.subtitle.fontFamily,
                          fontStyle: promo.subtitle.italic,
                          textDecoration:
                            promo.subtitle.underline +
                            promo.subtitle.lineThrough,
                          paddingLeft:
                          (that.state.actualWidth *
                            promo.subtitle.paddingLeftRight) /
                          promo.background.widthContainer +
                            `px`,
                          paddingRight:
                          (that.state.actualWidth *
                            promo.subtitle.paddingLeftRight) /
                          promo.background.widthContainer +
                            `px`,
                        }}
                      >
                        {promo.subtitle.text}
                      </p>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        ) : null}
      </>
    );
  }
}

export default Promotion;
