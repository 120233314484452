import React from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal,
  Form,
  Col,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getRestaurantsOfUser } from "../../api/Api";

class Disapatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_delete: false,
      show_modify: false,
      dispatch: "",
      zindex: this.props.item.zIndex,
      zindex_canc: this.props.item.zIndex,
      width_size: this.props.item.widhtSize,
      height_size: this.props.item.heightSize,
      color_bg: this.props.item.background,
      color_bg_canc: this.props.item.background,
      display_validation: "",
      textDispatch: this.props.item.textDispatch,
      id_restaurant: this.props.item.restaurantID,
      id_restaurant_canc: this.props.item.restaurantID,
      restaurants:[],
    };
    this.handleChangeColorBg = this.handleChangeColorBg.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleChangeIDRestaurant = this.handleChangeIDRestaurant.bind(this);
    this.handleChangeTextDispatch = this.handleChangeTextDispatch.bind(this);
    this.handleGetTextDispatch = this.handleGetTextDispatch.bind(this);

  }

  componentDidMount() {

    getRestaurantsOfUser()
      .then((res) => {
        this.setState({ restaurants: res });
      })
  }

  passDispatchProps = () => {
    this.props.onRemoveItem(this.props.item.i);
    this.props.handleDispatch();
  };

  //MODAL DELETE
  //Close
  handleCloseDelete = () => {
    this.setState({ show_delete: false });
  };
  //Open
  handleShowDelete = () => {
    this.setState({ show_delete: true });
  };
  //MODAL MODIFY
  //Close
  handleCloseModify = () => {
    this.setState({ show_modify: false });
  };
  //Open
  handleShowModify = () => {
    this.setState({ show_modify: true });
  };

  //Cahnge SIZE
  changeSize_1 = () => {
    this.setState({ height_size: 54 });
    this.setState({ width_size: 83 });

    this.props.item.widhtSize = this.state.width_size;
    this.props.item.heightSize = this.state.height_size;

    this.confirmChangeonModal();

    this.props.onDispatchSizeChange(this.props.item, 83, 54);
  };
  changeSize_2 = () => {
    this.setState({ height_size: 81 });
    this.setState({ width_size: 112 });

    this.props.item.widhtSize = this.state.width_size;
    this.props.item.heightSize = this.state.height_size;

    this.confirmChangeonModal();

    this.props.onDispatchSizeChange(this.props.item, 112, 81);
  };

  //Change ID Restaurants
  handleChangeIDRestaurant = (event) => {
    //Clear the drop down menu of the zone
    this.setState({ zones: [] });
  

    this.setState({ id_restaurant: event.target.value });

  };


  //Change Text
  handleChangeTextDispatch(event) {
    this.setState({ textDispatch: event.target.value });
  }

  handleGetTextDispatch() {
    this.setState({ dispatch: this.state.textDispatch });
  }

  //Cahnge Color Background
  handleChangeColorBg(event) {
    this.setState({ color_bg: event.target.value });
  }

  //Change Index
  handleChangeIndex(event) {
    if (event.target.value > 999) {
      this.setState({ zindex: 999 });
    } else if (event.target.value < 1) {
      this.setState({ zindex: 1 });
    } else {
      this.setState({ zindex: event.target.value });
    }
    this.props.onLayerChange(this.props.item.i, event.target.value);
  }

  //Cancel Changes
  cancelOnModal = () => {
    this.setState({ color_bg: this.state.color_bg_canc });
    this.setState({ zindex: this.state.zindex_canc });
    this.setState({ id_restaurant: this.stateid_restaurant_canc });
    this.props.onLayerChange(this.props.item.i, this.state.zindex_canc);
    this.handleCloseModify();
  };
  //Confirm Changes
  confirmChangeonModal = () => {
    this.setState({ color_bg_canc: this.state.color_bg });
    this.setState({ zindex_canc: this.state.zindex });
    this.setState({ id_restaurant_canc: this.state.id_restaurant });

    this.props.item.background = this.state.color_bg;
    this.props.item.restaurantID = this.state.id_restaurant;
    this.props.item.widthSize = this.state.width_size;
    this.props.item.heightSize = this.state.height_size;
    this.props.item.textDispatch = this.state.textDispatch;

    this.props.onStyleChange(this.props.item);

    this.handleCloseModify();
  };

  render() {
    const divStyle = {
      padding: "0",
      backgroundColor: this.state.color_bg,
      width: this.state.width_size * 10 + "px",
      height: this.state.height_size * 10 + "px",
      boxShadow: " 5px 10px 30px rgba(0,0,0,.2)",
      display: "flex",
      flexDirection: "column",
    };

    const mapStyle = {
      padding: "15px 0",
      width: "100%",
      height: "100%",
    };
    return (
      <>
        <div style={divStyle}>
          <Button
            variant="primary"
            className="circlebutton"
            onClick={this.handleShowModify}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>

          <Button
            className="circlebutton"
            variant="danger"
            onClick={this.handleShowDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.dispatch }}
              style={mapStyle}
            />
          </div>
        </div>

        {/* MODAL MODIFY */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
           fade={false}
           animation={false}
            className="sideMenu"
            show={this.state.show_modify}
            onHide={this.cancelOnModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName="backdrop_sideMenu"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Dispatch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* SELECT SIZE*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Select Size</Form.Label>
                      <Col>
                        <ButtonToolbar aria-label="Toolbar with button groups">
                          <ButtonGroup
                            className="mr-2"
                            aria-label="First group"
                          >
                            <Button onClick={this.changeSize_1}>1</Button>
                            <Button onClick={this.changeSize_2}>2</Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Text Dispatch */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Text Dispatch </Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="textarea"
                          value={this.state.textDispatch}
                          onChange={this.handleChangeTextDispatch}
                        />
                        <Form.Text className="text-muted">
                          <Button onClick={this.handleGetTextDispatch}>Ok</Button>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* ID Restaurant*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Restaurant Selection</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeIDRestaurant}
                          value={this.state.id_restaurant}
                        >
                          <option value="">

                          </option>
                          {this.state.restaurants.map((restaurant) => {

                            return (
                              <option value={restaurant.id}>
                                {restaurant.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                      <Form.Text className="text-muted">
                        {this.state.text}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
               
                {/* BACKGROUND COLOR */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Background</Form.Label>

                      <Col md={6}>
                        <Form.Control
                          contentEditable
                          type="color"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Z-Index</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.zindex}
                          onChange={this.handleChangeIndex}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          z-index: {this.state.zindex}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.cancelOnModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.confirmChangeonModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL DELETE */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            show={this.state.show_delete}
            onHide={this.handleCloseDelete}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Dispatch</Modal.Title>
            </Modal.Header>
            <Modal.Body>wonna delete?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseDelete}>
                Close
              </Button>
              <Button onClick={this.passDispatchProps} variant="danger">
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default Disapatch;
