import Item from './Item';

class BackgroundModel extends Item {

    constructor(i, x, maxH, maxW, minH, minW) {

        super(i, x, maxH, maxW, minH, minW);
        this.backgroundColor = "#D6D6D6";
        this.backgroundImage = "";
    }

}

export default BackgroundModel;