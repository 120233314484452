import React from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import FontList from "../../styles/FontList";

const listFont = FontList;

class Saint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_delete: false,
      show_modify: false,
      color_bg: this.props.item.background,
      color_bg_canc: this.props.item.background,
      color_text: this.props.item.color,
      color_text_canc: this.props.item.color,
      fontfamily: this.props.item.textFont,
      fontfamily_canc: this.props.item.textFont,
      fontsize: this.props.item.textSize,
      fontsize_canc: this.props.item.textSize,
      switchbackground: true,
      zindex: this.props.item.zIndex,
      zindex_canc: this.props.item.zIndex,
    };
    this.handleChangeColorBg = this.handleChangeColorBg.bind(this);
    this.handleChangeColorText = this.handleChangeColorText.bind(this);
    this.handleChangeFontFamily = this.handleChangeFontFamily.bind(this);
    this.handleChangeFontSize = this.handleChangeFontSize.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
  }
  //MODAL DELETE
  //Close
  handleCloseDelete = () => {
    this.setState({ show_delete: false });
  };
  //Open
  handleShowDelete = () => {
    this.setState({ show_delete: true });
  };
  //MODAL MODIFY
  //Close
  handleCloseModify = () => {
    this.setState({ show_modify: false });
  };
  //Open
  handleShowModify = () => {
    this.setState({ show_modify: true });
  };
  //Change Color Background
  handleChangeColor(event) {
    this.setState({ color_bg: event.target.value });
  }
  //Transparent Background
  handleswitchBackground = (event) => {
    this.setState({ switchbackground: event.target.checked });
    if (this.state.switchbackground === true) {
      this.setState({ color_bg: "transparent" });
    } else {
      this.setState({ color_bg: this.state.color_bg_canc });
    }
  };

  //Change Color Text
  handleChangeColorText(event) {
    this.setState({ color_text: event.target.value });
  }
  //Change Color Background
  handleChangeColorBg(event) {
    this.setState({ color_bg: event.target.value });
  }
  //Change Font Size
  handleChangeFontSize(event) {
    if (event.target.value > 25) {
      this.setState({ fontsize: 25 });
    } else if (event.target.value < 20) {
      this.setState({ fontsize: 20 });
    } else {
      this.setState({ fontsize: event.target.value });
    }
  }
  //Change Font Family
  handleChangeFontFamily(event) {
    this.setState({ fontfamily: event.target.value });
  }
  //Change Index
  handleChangeIndex(event) {
    if (event.target.value > 999) {
      this.setState({ zindex: 999 });
    } else if (event.target.value < 1) {
      this.setState({ zindex: 1 });
    } else {
      this.setState({ zindex: event.target.value });
    }
    this.props.onLayerChange(this.props.item.i, event.target.value);
  }

  //Cancel Changes
  cancelOnModal = () => {
    this.setState({ color_bg: this.state.color_bg_canc });
    this.setState({ color_text: this.state.color_text_canc });
    this.setState({ fontfamily: this.state.fontfamily_canc });
    this.setState({ fontsize: this.state.fontsize_canc });
    this.setState({ zindex: this.state.zindex_canc });
    this.props.onLayerChange(this.props.item.i, this.state.zindex_canc);
    this.handleCloseModify();
  };
  //Confirm Changes
  confirmChangeonModal = () => {
    this.setState({ color_bg_canc: this.state.color_bg });
    this.setState({ color_text_canc: this.state.color_text });
    this.setState({ fontfamily_canc: this.state.fontfamily });
    this.setState({ fontsize_canc: this.state.fontsize });
    this.setState({ zindex_canc: this.state.zindex });

    this.props.item.background = this.state.color_bg;
    this.props.item.color = this.state.color_text;
    this.props.item.textFont = this.state.fontfamily;
    this.props.item.textSize = this.state.fontsize;

    this.props.onStyleChange(this.props.item);

    this.handleCloseModify();
  };

  render() {
    const divStyle = {
      overflow: "hidden",
      display: "flex",
      textAlign: "center",
      backgroundColor: this.state.color_bg,
      width: "100%",
      height: "100%",
      fontFamily: this.state.fontfamily,
      color: this.state.color_text,
      border: "1px solid #1e1e338c",
    };

    const textSaint = {
      fontFamily: this.state.fontfamily,
      color: this.state.color_text,
      fontSize: this.state.fontsize + "px",
      alignSelf: "center",
      textAlign: "center",
      width: "100%",
      margin: "0",
    };

    return (
      <>
        <div style={divStyle}>
          <Button
            variant="primary"
            className="circlebutton"
            onClick={this.handleShowModify}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
          <Button
            className="circlebutton"
            variant="danger"
            onClick={this.handleShowDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <h1 style={textSaint}>Sainte Jeanne-Françoise de Chantal</h1>
        </div>

        {/* MODAL MODIFY */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
           fade={false}
           animation={false}
            className="sideMenu"
            show={this.state.show_modify}
            onHide={this.cancelOnModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName="backdrop_sideMenu"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Saint</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* BACKGROUND COLOR */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Background</Form.Label>

                      <Col md={12} className="my-2">
                        <Form.Check
                          checked={this.state.switchbackground}
                          onChange={this.handleswitchBackground}
                          type="switch"
                          id="custom-switch"
                          label="Background Color"
                        />
                      </Col>

                      <Col md={6}>
                        <Form.Control
                          disabled={!this.state.switchbackground}
                          type="color"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />
                        <Form.Control
                          disabled={!this.state.switchbackground}
                          className="my-2"
                          type="text"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* FONTFAMILY */}
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamily}
                          value={this.state.fontfamily}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FONT SIZE TITLE*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontsize}
                          onChange={this.handleChangeFontSize}
                          min="20"
                          max="25"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontsize}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Color Text */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Text</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_text}
                          onChange={this.handleChangeColorText}
                        />

                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_text}
                          onChange={this.handleChangeColorText}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Change Index */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Z-Index</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.zindex}
                          onChange={this.handleChangeIndex}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          z-index: {this.state.zindex}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.cancelOnModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.confirmChangeonModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL DELETE */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            show={this.state.show_delete}
            onHide={this.handleCloseDelete}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Saint</Modal.Title>
            </Modal.Header>
            <Modal.Body>wonna delete?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseDelete}>
                Close
              </Button>
              <Button
                onClick={() => this.props.onRemoveItem(this.props.item.i)}
                variant="danger"
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default Saint;
