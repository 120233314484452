import Item from './Item';

class LogoModel extends Item {

    constructor(i, x, maxH, maxW, minH, minW) {

        super(i, x, maxH, maxW, minH, minW);
        this.image = ""
    }

}

export default LogoModel;