import Item from "./Item";

class FluidityInfoModel extends Item {
  constructor(i, x, maxH, maxW, minH, minW) {
    super(i, x, maxH, maxW, minH, minW);
    this.fontSize = 18;
    this.fontFamily = "Montserrat";
    this.colorDescription = "#323232";

    this.fontSizeHeader = 35;
    this.fontFamilyHeader = "Montserrat";
    this.colorHeader = "#323232";

    this.fontSizeTitle = 25;
    this.fontFamilyTitle = "Montserrat";
    this.colorTitle = "#323232";

    this.fluidityZone = [
      {
        key: 0,
        id_zone: "",
        text_zone: "",
        zone_valuelive: "",
        zone_validation: "",
        border_color: "",
        title: "Places disponibles",
        description: "il sera très facile de trouver une table libre",
      },
      {
        key: 1,
        id_zone: "",
        text_zone: "",
        zone_valuelive: "",
        zone_validation: "",
        border_color: "",
        title: "Places disponibles",
        description: "il sera très facile de trouver une table libre",
      },
      {
        key: 2,
        id_zone: "",
        text_zone: "",
        zone_valuelive: "",
        zone_validation: "",
        border_color: "",
        title: "Places disponibles",
        description: "il sera très facile de trouver une table libre",
      },
      {
        key: 3,
        id_zone: "",
        text_zone: "",
        zone_valuelive: "",
        zone_validation: "",
        border_color: "",
        title: "Places disponibles",
        description: "il sera très facile de trouver une table libre",
      },
      {
        key: 4,
        id_zone: "",
        text_zone: "",
        zone_valuelive: "",
        zone_validation: "",
        border_color: "",
        title: "Places disponibles",
        description: "il sera très facile de trouver une table libre",
      },
      {
        key: 5,
        id_zone: "",
        text_zone: "",
        zone_valuelive: "",
        zone_validation: "",
        border_color: "",
        title: "Places disponibles",
        description: "il sera très facile de trouver une table libre",
      },
      {
        key: 6,
        id_zone: "",
        text_zone: "",
        zone_valuelive: "",
        zone_validation: "",
        border_color: "",
        title: "Places disponibles",
        description: "il sera très facile de trouver une table libre",
      },
      {
        key: 7,
        id_zone: "",
        text_zone: "",
        zone_valuelive: "",
        zone_validation: "",
        border_color: "",
        title: "Places disponibles",
        description: "il sera très facile de trouver une table libre",
      },
    ];
    this.restaurants = [];
    this.restaurantSelected = 0;
  }
}

export default FluidityInfoModel;
