import React from "react";
import {
    Image
} from "react-bootstrap";
import { getFoodGroupByID } from "../../../api/Api";


class ImageFoodGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUploaded: this.props.ImageUploaded,
            imageFromFoodGroup: "",
            headerImageSelected: "",
        };
    }
    componentDidMount() {
        this.getFoodItems()
        this.hideLittleIcons()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ImageUploaded !== this.props.ImageUploaded) {
            this.setState({ imageUploaded: this.props.ImageUploaded })

        }
    }

    hideLittleIcons = () => {
        var imageToHide = document.getElementsByClassName("hideIfLittle")
        if (imageToHide && imageToHide[0]) {
            Array.from(imageToHide).forEach(element => {
                var intElemOffsetHeight = element.offsetHeight;
                if (intElemOffsetHeight < 100) {
                    element.style.display = "none"
                }
            });
        }
    }

    //get FOOD Item FROM FOOD GROUPID
    getFoodItems = () => {
        getFoodGroupByID(this.props.id_foodgroup).then((res) => {
            if (res) {
                let svgClear = res.picto.replace(/\\/g, "").replace("<style>", "");
                this.setState({ imageFromFoodGroup: svgClear })
            } else {
                console.log("error")
            }
        });
    };


    render() {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        const foodItemImageStyle = {
            display: "flex",
            objectFit: "cover",
            width: "100%",
            //  width: "auto",
            height: "0",
            minHeight: "100%",
            flexShrink: "0",

        };
        const imageVector = {
            display: "flex",
            flex: "1",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: this.props.color_accent,
        };
        const imageContainer = {
            height: "100%",
            width: "100%",
            display: "flex",
        };


        if (this.props.foodItemImage !== '') {
            return (

                <div style={imageContainer} className="hideIfLittle">
                    <Image
                        src={this.props.foodItemImage}
                        style={foodItemImageStyle} />
                </div>

            )
        } else if ((this.state.imageUploaded != null && this.state.imageUploaded !== "") && !pattern.test(this.state.imageUploaded)) {
            return (
             
                    <div
                        className="resizeSVGImageFoodGroup hideIfLittle"
                        dangerouslySetInnerHTML={{ __html: this.state.imageUploaded }}
                        style={imageVector} />
             
            )
        } else if ((this.state.imageUploaded != null && this.state.imageUploaded !== "") && pattern.test(this.state.imageUploaded)) {
            return (

                <div style={imageContainer} className="hideIfLittle">
                    <Image src={this.state.imageUploaded} style={foodItemImageStyle} />
                </div>

            )
        } else if (this.state.imageFromFoodGroup !== "") {
            return (
             
                    <div
                        className="resizeSVGImageFoodGroup hideIfLittle"
                        dangerouslySetInnerHTML={{ __html: this.state.imageFromFoodGroup }}
                        style={imageVector}
                    />
              
            )
        } else {
            return (

                <div
                className="resizeSVGImageFoodGroup hideIfLittle"
            >

            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <defs>
                    <clipPath id="clip-path">
                        <rect id="Rettangolo_4" data-name="Rettangolo 4" width="50" height="28.184" fill="#fff" />
                    </clipPath>
                    <clipPath id="clip-piatto_forchette">
                        <rect width="50" height="50" />
                    </clipPath>
                </defs>
                <g id="piatto_forchette" clip-path="url(#clip-piatto_forchette)">
                    <rect id="Rettangolo_2" data-name="Rettangolo 2" width="50" height="50" fill="none" />
                    <g id="Raggruppa_4" data-name="Raggruppa 4" transform="translate(0 10.908)">
                        <g id="Raggruppa_3" data-name="Raggruppa 3" clip-path="url(#clip-path)">
                            <path id="Tracciato_2" data-name="Tracciato 2" d="M8.24,4.32V11.6c-.062.175-.778.165-.824,0-.072-1.3-.4-7.168-.412-7.308-.165-1.072-2.3-1.072-2.468,0-.005.134-.34,6-.412,7.308-.046.165-.757.175-.824,0V4.32C3.164,3.2.994,3.217.83,4.263A62.809,62.809,0,0,0,0,10.9a5.432,5.432,0,0,0,3.551,5.391l-.665,12.27a1.24,1.24,0,0,0,1.237,1.3h3.3a1.24,1.24,0,0,0,1.237-1.3l-.665-12.27A5.434,5.434,0,0,0,11.544,10.9a62.978,62.978,0,0,0-.83-6.637c-.165-1.046-2.34-1.057-2.474.057" transform="translate(0 -2.547)" fill="#fff" />
                            <path id="Tracciato_3" data-name="Tracciato 3" d="M162.8,3.48c-4.251,0-11.41,9.2-3.344,15.507l-.773,9.539a1.237,1.237,0,0,0,1.232,1.34H162.8a1.234,1.234,0,0,0,1.237-1.237V4.717A1.237,1.237,0,0,0,162.8,3.48" transform="translate(-114.033 -2.547)" fill="#fff" />
                            <path id="Tracciato_4" data-name="Tracciato 4" d="M61.4,0A14.092,14.092,0,1,0,75.49,14.092,14.092,14.092,0,0,0,61.4,0m0,25.483A11.358,11.358,0,1,1,72.756,14.126,11.358,11.358,0,0,1,61.4,25.483" transform="translate(-34.627)" fill="#fff" />
                            <path id="Tracciato_5" data-name="Tracciato 5" d="M71.525,13.836A10.384,10.384,0,1,0,81.908,24.22,10.384,10.384,0,0,0,71.525,13.836" transform="translate(-44.753 -10.128)" fill="#fff" />
                        </g>
                    </g>
                </g>
            </svg>
            </div>
  )
        }
    }
}

export default ImageFoodGroup;
