import React from "react";
import { getRegionByID, getValueLive } from "../../api/Api";

class FluidityLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_zone: this.props.item.zona,
      zone_valuelive: "",
      capacity: "",
      line_color: "#d6d6d6",
      zone_name: "ZONE NAME",
    };
  }

  componentDidMount() {
    this.confirmZone();
    // set Interval
    this.interval = setInterval(this.confirmZone.bind(this), 60*1000)
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  //Get Region
  //getRegionByID
  confirmZone = () => {
    const id_zone = this.props.item.zona;
    this.setState({ zone_valuelive: "" });

    getValueLive(id_zone).then((res) => {
      if (res == null) {
        this.setState({ zone_valuelive:0 });
      }else{
      this.setState({ zone_valuelive: res[0].value_live });
}
    
    }).then(() => {
    getRegionByID(id_zone).then((res) => {
      this.setState({ line_color: "" });
      if (res[0]) {
        this.setState({ zone_name: res[0].name });
          this.setState({
            capacity: (100 / res[0].max_capacity) * this.state.zone_valuelive,
          });
      
        if (
          this.state.capacity < res[0].fluidity_conf.fluidity_triggers[1].level
        ) {
          this.setState({
            line_color: res[0].fluidity_conf.fluidity_triggers[0].color,
          });
        } else if (
          this.state.capacity < res[0].fluidity_conf.fluidity_triggers[2].level
        ) {
          this.setState({
            line_color: res[0].fluidity_conf.fluidity_triggers[1].color,
          });
        } else if (this.state.capacity < 100) {
          this.setState({
            line_color: res[0].fluidity_conf.fluidity_triggers[2].color,
          });
        }

        this.setState({
          fluidity_triggers: res[0].fluidity_conf.fluidity_triggers,
        });
        this.setState({ fluidity_triggers: res[0].max_capacity });
      } else {
        this.setState({
          line_color: "#d6d6d6",
        });
        this.setState({ capacity: 100 });
        this.setState({ zone_name: "ZONE NAME" });
      }
    });
  });
  };

  render() {
    const divStyle = {
      overflow: "hidden",
      padding: "5px",
      width: "100%",
      height: "100%",
      fontFamily: this.props.item.textFont,
      color: this.props.item.textColor,
      fontSize: this.props.item.textSize + "px",
    };
    const TextStyle = {
      color: this.props.item.textColor,
      fontSize: this.props.item.textSize + "px",
      textAlign: "center",
      marginBottom: "0",
    };
    const lineStyle = {
      backgroundColor: this.props.item.background,
      padding: "10px",
      fontSize: this.props.item.textSize + "px",
    };

    const ProgressStyle = {
      backgroundColor: this.state.line_color,
      width: this.state.capacity + "%",
    };
    return (
      <>
        <div className="container_component">
          <div style={divStyle}>
            <h1 style={TextStyle}>{this.state.zone_name}</h1>
            <div style={lineStyle}>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow={this.state.capacity}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={ProgressStyle}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FluidityLine;
