import { ThemeProvider } from "react-bootstrap";
import Item from "./Item";

class PromotionModel extends Item {
  constructor(i, x, maxH, maxW, minH, minW) {
    super(i, x, maxH, maxW, minH, minW); 
    this.tag = "";
    this.id = 0;
  }
}

export default PromotionModel;
