import React from "react";

class Background extends React.Component {
  
  render() {
    const divStyle = {
      backgroundColor: this.props.item.backgroundColor,
      width: "100%",
      height: "100%",
      transition: "all 250ms ease",
      backgroundImage: `url(${this.props.item.backgroundImage})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };

    return (
      <>
        <div style={divStyle}></div>
      </>
    );
  }
}

export default Background;
