import Item from './Item';

class MidLineHorizontalModel extends Item {

    constructor(i, x, maxH, maxW, minH, minW) {

        super(i, x, maxH, maxW, minH, minW);
        this.color = "#999999";
    }
}

export default MidLineHorizontalModel;