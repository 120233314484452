import React from "react";
import { Col, Image } from "react-bootstrap";
import { getZonesOfDisplayByRestaurant, getRegionByID } from "../../api/Api";
import iconRestaurant from "../../image/iconrestaurants.png";

class InfoFluidity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_place: this.props.item.restaurantSelected,
      zones: [],
      zone_validation: "",
      fluidityZone: this.props.item.fluidityZone,
    };
  }

  componentDidMount() {
    this.getZone()
    this.interval = setInterval(this.getZone.bind(this), 60000)


  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }



  //get ZONE FROM DISPLAY ID
  getZone = () => {

    if (this.state.id_place) {
      getZonesOfDisplayByRestaurant(this.state.id_place).then((res) => {
        this.fluidityInfo(res)
        
      });
    }
    
  };



  fluidityInfo(zonelist) {

    let ready = zonelist;

    if (ready && ready[0]) {
      //let  fluidityZone=this.state.fluidityZone;

      zonelist.map((zona) => {

        if (zona.capacity === 0) {
          //Grey (the max capacity is Zero)
          this.setState((prevState) => ({
            fluidityZone: prevState.fluidityZone.map((el) =>
              el.id_zone === String(zona.region_id) ? {
                ...el,
                border_color: "#c6c6c6",
                title: "Zone fermée",
                description:
                  "",
              } : el
            ),
          }));
        }
        else if ((zona.level * 100) < zona.fluidity_conf.fluidity_triggers[1].level) {
          //Green
          this.setState((prevState) => ({
            fluidityZone: prevState.fluidityZone.map((el) =>
              el.id_zone === String(zona.region_id) ? {
                ...el,
                border_color: zona.fluidity_conf.fluidity_triggers[0].color,
                title: "Places disponibles",
                description:
                  "il sera très facile de trouver une table libre",
              } : el
            ),
          }));

        } else if ((zona.level * 100) < zona.fluidity_conf.fluidity_triggers[2].level) {
          //Orange
          this.setState((prevState) => ({
            fluidityZone: prevState.fluidityZone.map((el) =>
              el.id_zone === String(zona.region_id) ? {
                ...el,
                border_color: zona.fluidity_conf.fluidity_triggers[1].color,
                title: "Encore quelques places",
                description:
                  "il sera plus compliqué de trouver une table libre",
              } : el
            ),
          }));
        } else {
          //Red
          this.setState((prevState) => ({
            fluidityZone: prevState.fluidityZone.map((el) =>
              el.id_zone === String(zona.region_id) ? {
                ...el,
                border_color: zona.fluidity_conf.fluidity_triggers[2].color,
                title: "C’est complet !",
                description:
                  "il sera très difficile de trouver une table libre",
              } : el
            ),
          }));
        }


      })
    }
  }


  render() {
    const divStyle = {
      margin: "0 4px",
      padding: "20px 8px",
      height: "100%",
      boxShadow: " 5px 10px 30px rgba(0,0,0,.2)",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
      width: "100%",
      overflow: "hidden",
    };
    const IconStyle = {
      backgroundColor: this.props.item.colorHeader,
      width: "60px",
      height: "60px",
      marginBottom: "10px",
      marginTop: "15px",
      borderRadius: "100%",
      alignSelf: "center",
    };
    const headerStyle = {
      fontSize: this.props.item.fontSizeHeader + "px",
      color: this.props.item.colorHeader,
      fontFamily: this.props.item.fontFamilyHeader,
      marginBottom: "20px",
    };
    const squareStyle = {
      overflow: "hidden",
      margin: "4px",
      border: "5px solid #d6d6d6",
      height: "70px",
      width: "70px",
      borderRadius: "15%",
      backgroundColor: "#FFF",
      boxShadow: "rgb(0 0 0 / 57%) 0px 4px 5px",
    };
    const titleStyle = {
      margin: "0",
      fontWeight: "bold",
      fontFamily: this.props.item.fontFamilyTitle,
      fontSize: this.props.item.fontSizeTitle + "px",
      color: this.props.item.colorTitle,
    };
    const desctriptionStyle = {
      margin: "0",
      fontFamily: this.props.item.fontFamily,
      fontSize: this.props.item.fontSize + "px",
      color: this.props.item.colorDescription,
    };

    return (
      <>
        <div className="container_component">
          <div style={divStyle}>
            <div style={IconStyle} className="newshadow">
              <Image src={iconRestaurant} fluid />
            </div>
            <h2 className="text-center " style={headerStyle}>
              OÙ S’ASSEOIR POUR DÉJEUNER ?
            </h2>
            <div className="listcontainer-infoFluidity">
              {this.state.fluidityZone !== "" &&
                this.state.fluidityZone
                  .filter(function (value) {
                    return value.id_zone !== "";
                  })
                  .map(function (zone, i) {
                    return (
                      <div className="justify-content-md-center d-flex item-infoFluidity">
                        <Col md="auto">
                          <div
                            style={
                              {
                                ...squareStyle,
                                ...{ borderColor: zone.border_color }
                              }}
                          >
                            <h3 className="number-infoFluidity">
                              {zone.text_zone}
                            </h3>
                          </div>
                        </Col>
                        <Col xs className="textcontainer-infoFluidity">
                          <h3 style={titleStyle}>{zone.title}</h3>
                          <p style={desctriptionStyle}>{zone.description}</p>
                        </Col>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default InfoFluidity;
