import Item from './Item';

class WeatherModel extends Item {

    constructor(i, x, maxH, maxW, minH, minW) {

        super(i, x, maxH, maxW, minH, minW);
        this.background = "#F0F0F0";
        this.color = "#999999";
        this.textFont = "Montserrat";
        this.textSize = 20;
        this.icon = "";
        this.temperatureFont = "Montserrat";
        this.iconSize = "42px";
    }

}

export default WeatherModel;