import Item from "./Item";

class TitleBarModel extends Item {
  constructor(i, x, maxH, maxW, minH, minW) {
    super(i, x, maxH, maxW, minH, minW);
    this.color = "#F0F0F0";
    this.icon = "";
    this.backgroundIcon = "#999999";
    this.text = "";
    this.backgroundText = "#f6f6f6";
    this.textColor = "#999999";
    this.textFont = "Montserrat";
    this.textSize = 50;
    this.id = 0;
    this.textAlign = "";
  }
}

export default TitleBarModel;
